import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import PaymentView from 'src/constructors/PaymentView';
import { groupPayments } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { paymentMethodsIframeRequest, paymentMethodsRequest } from 'src/services/userApi';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { RootState } from 'src/types/store-types';
import _styles from './styles.module.scss';

const Payment: FC = () => {
  const { t } = useTranslation();
  const { accountModal } = useSelector((state: RootState) => state);
  const { wallets } = useSelector((state: RootState) => state.user);
  const { user } = useSelector((state: RootState) => state.user);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paymentMethodsData, setPaymentMethodsData] = useState<PaymentMethod[] | null>([]);
  const [selectedPaymentMethodsData, setSelectedPaymentMethodsData] = useState<PaymentMethod[] | null>([]);
  const [additionalFields, setAdditionalFields] = useState<any | null>(null);
  const [paymentFields, setPaymentFields] = useState<any>(null);
  const [showTransactionResult, setShowTransactionResult] = useState<boolean>(false);
  const [groupedPayments, setGroupedPayments] = useState<{
    deposit: PaymentMethod[];
    withdrawel: PaymentMethod[];
  } | null>(null);
  const isNewPaymentMethod = process.env.REACT_APP_NEW_PAYMENT_METHOD === 'true';

  const _openAccountModal = useConnectedAction(openMyAccountModal);

  const mainWalletCurrency = useMemo(() => {
    return wallets?.find((f: any) => f.walletType === 'main')?.currency;
  }, [wallets]);

  const handleNavigate = (): void => {
    _openAccountModal({ section: 'my_account', subSection: 'personal_info' });
  };

  const getPaymentMethods = async (): Promise<void> => {
    setIsLoading(true);

    try {
      const requestFn = isNewPaymentMethod ? paymentMethodsIframeRequest : paymentMethodsRequest;

      const response: any = await requestFn({ currency: mainWalletCurrency || wallets?.[0]?.currency });

      if (response?.success) {
        const paymentMethods = response.result.paymentMethods;

        const depositMethods = paymentMethods.filter((method: any) => method.action === 'deposit');
        const withdrawalMethods = paymentMethods.filter((method: any) => method.action === 'withdraw');

        setGroupedPayments({
          deposit: groupPayments(depositMethods),
          withdrawel: groupPayments(withdrawalMethods),
        });

        setPaymentMethodsData(paymentMethods);
      } else {
        setPaymentMethodsData(null);
      }
    } catch (error) {
      console.error('Failed to fetch payment methods:', error);
      setPaymentMethodsData(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setAdditionalFields(null);
    setPaymentFields(null);
    setShowTransactionResult(false);
    if (paymentMethodsData?.length === 0) {
      getPaymentMethods();
    }
  }, [accountModal.section, paymentMethodsData]);

  useEffect(() => {
    if (paymentMethodsData && paymentMethodsData?.length > 0) {
      setSelectedPaymentMethodsData(
        (paymentMethodsData as PaymentMethod[]).filter((method) => accountModal.section.includes(method.action))
      );
    }

    if (paymentMethodsData === null) {
      setSelectedPaymentMethodsData(null);
    }
  }, [accountModal.section, paymentMethodsData]);

  if (
    process.env.REACT_APP_WITHDRAWEL_BLOCK === 'true' &&
    accountModal.section === 'withdrawel' &&
    process.env.REACT_APP_EMAIL_VERIFICATION === 'true' &&
    !user?.personalInfo?.emailVerified
  ) {
    return (
      <div className={_styles.container}>
        <p>{t('verifyEmailForWithdrawel')}</p>
        <Button className={_styles.verifyBtn} onClick={handleNavigate}>
          {t('goToVerifyEmail')}
        </Button>
      </div>
    );
  }

  return (
    <PaymentView
      groupedPayments={groupedPayments?.[accountModal.section]}
      paymentMethodsData={selectedPaymentMethodsData}
      method={accountModal.section}
      isLoading={isLoading}
      setAdditionalFields={setAdditionalFields}
      additionalFields={additionalFields}
      paymentFields={paymentFields}
      setPaymentFields={setPaymentFields}
      showTransactionResult={showTransactionResult}
      setShowTransactionResult={setShowTransactionResult}
      setIsLoading={setIsLoading}
    />
  );
};

export default Payment;
