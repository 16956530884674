import { useSelector } from 'react-redux';
import { envBoolean } from 'src/helpers/utils';
import { sessionRecordingService } from 'src/services/sessionRecordingService';
import { RootState } from 'src/types/store-types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSessionRecorder = () => {
  const user = useSelector((state: RootState) => state.user.user);

  if (
    process.env.NODE_ENV === 'development' ||
    !envBoolean(process.env.REACT_APP_SESSION_RECORDING as string) ||
    !user
  ) {
    return null;
  }

  return {
    identifyUser: sessionRecordingService.identifyUser,
    trackEvent: sessionRecordingService.trackEvent,
  };
};
