import cn from 'classnames';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { usePostMesssage } from 'src/hooks/use-postMessage';
import { getHeaderConfigs } from 'src/services/configsApi';
import PersisterService from 'src/services/PersisterService';
import { setGeneraLoading } from 'src/store/app/actions';
import { setHeaderConfigs } from 'src/store/configs/actions';
import { RootState } from 'src/types/store-types';
import { Constructor } from './constructor';
import './styles.scss';

const DesktopHeaderContructor: FC = () => {
  const _setGeneraLoading = useConnectedAction(setGeneraLoading);
  const _setHeaderConfigs = useConnectedAction(setHeaderConfigs);

  const { user } = useSelector((state: RootState) => state.user);
  const { customI18n } = useSelector((state: RootState) => state.app);

  const [headerTemplate, setHeaderTemplate] = useState<string>('header-1Row');
  const [configs, setConfigs] = useState<HeaderSettings>({
    topLine: { type: '', sections: {} },
    styles: {},
  });

  const topLineWidgetsArr = Object.values(configs?.topLine?.sections || {});
  const middleLineWidgetsArr = Object.values(configs?.middleLine?.sections || {});
  const bottomLineWidgetsArr = Object.values(configs?.bottomLine?.sections || {});

  const renderWidgets = useCallback(
    (settings: { [key: string]: any }, idx: number, initialSettings: any, mainSection: string): JSX.Element => {
      return (
        <div style={settings.styles} className={Object.keys(initialSettings[mainSection].sections)[idx]}>
          {Constructor.renderWidgets(settings, idx, user)}
        </div>
      );
    },
    [configs, user]
  );

  const getHeaderConf = async (): Promise<void> => {
    try {
      if (PersisterService.hasPersistValue('desktopHeader')) {
        const res = PersisterService.getPersistValue('desktopHeader');
        _setHeaderConfigs(res.template);
        setConfigs(res.sections);
        setHeaderTemplate(res.template);
      } else {
        const res = await getHeaderConfigs('desktop');

        if (res.success) {
          _setGeneraLoading(true);
          _setHeaderConfigs(res.result.template);
          setConfigs(res.result.sections);
          setHeaderTemplate(res.result.template);
          PersisterService.setPersistValue('desktopHeader', res.result);
        } else {
          _setGeneraLoading(false);
        }
      }
    } catch (e) {
      _setGeneraLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    getHeaderConf();
  }, []);

  usePostMesssage((v: any) => setConfigs(v), 'desktopHeader');

  return (
    <div style={configs?.styles} className={cn('desktop_header', headerTemplate)}>
      {configs?.topLine.sections && (
        <div
          className={cn(configs.topLine.type, 'header_wrapper', {
            topLine_empty_newsLetter: topLineWidgetsArr.some((obj) => obj?.newsLetter) && !customI18n?.newsLetter,
          })}
        >
          {topLineWidgetsArr.map((obj: TypeObject, i: number) => {
            return <Fragment key={`topLine_widget_${i}`}>{renderWidgets(obj, i, configs, 'topLine')}</Fragment>;
          })}
        </div>
      )}
      {configs?.middleLine && configs?.middleLine.sections && (
        <div className={cn(configs.middleLine.type, 'header_wrapper')}>
          {middleLineWidgetsArr?.map((obj: TypeObject, i: number) => {
            return <Fragment key={`middleLine_widget_${i}`}>{renderWidgets(obj, i, configs, 'middleLine')}</Fragment>;
          })}
        </div>
      )}
      {configs?.bottomLine && configs?.bottomLine.sections && (
        <div className={cn(configs.bottomLine.type, 'header_wrapper')}>
          {bottomLineWidgetsArr?.map((obj: TypeObject, i: number) => {
            return <Fragment key={`bottomLine_widget_${i}`}>{renderWidgets(obj, i, configs, 'bottomLine')}</Fragment>;
          })}
        </div>
      )}
    </div>
  );
};
export default DesktopHeaderContructor;
