import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { checkDeviceCompatibility, formatCurrency, getSkinId, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { MediaManager } from 'src/manager/MediaManager';
import { getHighestWins } from 'src/services/winApi';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys, EnumStatusesOfKyc } from 'src/store/dialog/types';
import { setSelectedGame } from 'src/store/games/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

export const HighestWinsTable: FC = () => {
  const { cookiesAccepted } = useSelector((state: RootState) => state.app);
  const { user, gamePreviewProducts, kycStatus } = useSelector((state: RootState) => state.user);
  const [wins, setWins] = useState<any>([]);

  const { t } = useTranslation();
  const skinId = getSkinId();
  const deviceCompatibilityStatus = checkDeviceCompatibility().compatible;
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const _openAuthModal = useConnectedAction(openAuthModal);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _openDialog = useConnectedAction(openDialog);

  const getHighestWinsData = async (): Promise<any> => {
    const res = await getHighestWins(skinId);
    if (res?.result?.data) {
      setWins(res.result.data.map((item: any, index: number) => ({ ...item, position: index + 1 })));
    }
  };

  const columns = useMemo(() => {
    return isMobile
      ? [
          {
            key: 'game',
            accessor: 'gameName' as const,
          },
          {
            key: 'winAmount',
            accessor: 'won' as const,
          },
        ]
      : [
          {
            key: 'position',
            accessor: 'position' as const,
          },
          {
            key: 'game',
            accessor: 'gameName' as const,
          },
          {
            key: 'user',
            accessor: 'username' as const,
          },
          {
            key: 'time',
            accessor: 'date' as const,
          },
          {
            key: 'winAmount',
            accessor: 'won' as const,
          },
        ];
  }, [isMobile]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const onGameClick = (id: number): void => {
    const data = { id, productId: '' };

    if (
      process.env.REACT_APP_USE_COOKIES_GAMECARD === 'true' &&
      process.env.REACT_APP_USE_COOKIES === 'true' &&
      !cookiesAccepted
    ) {
      _openDialog({
        dialogType: EnumDialogsKeys.COOKIES_POPUP,
        dialogProps: { data: {} },
      });
    } else if (process.env.REACT_APP_DEVICE_COMPATIBILITY_ENABLE === 'true' && !deviceCompatibilityStatus) {
      _openDialog({
        dialogType: EnumDialogsKeys.DEVICE_COMPATIBILITY_POPUP,
        dialogProps: { data: {} },
      });
    } else if (
      user &&
      process.env.REACT_APP_KYC_ENABLE === 'true' &&
      (kycStatus === EnumStatusesOfKyc.PENDING ||
        kycStatus === EnumStatusesOfKyc.EXPIRED ||
        kycStatus === EnumStatusesOfKyc.REJECTED ||
        kycStatus === undefined)
    ) {
      _openDialog({
        dialogType: EnumDialogsKeys.KYC_POPUP,
        dialogProps: { data: {} },
      });
    } else {
      if (user) {
        setBodyOverflow('unset');
        if (generalConfigs?.gameLaunchPreview && gamePreviewProducts.includes(data.productId)) {
          _openDialog({
            dialogType: EnumDialogsKeys.GAME_LAUNCH_PREVIEW,
            dialogProps: { data: data },
          });
        } else {
          if (window.__gdlib?._gameBoxReady) {
            console.log('Success! The game has been opened.');
          } else {
            return;
          }
          _selectedGame(data);
        }
      } else {
        setBodyOverflow('unset');
        _openAuthModal('login');
      }
    }
  };

  useEffect(() => {
    getHighestWinsData();
  }, []);

  return (
    <>
      <Typography className="highest-wins-table-title" variant="h3">
        {t('highestWins')}
      </Typography>

      <table className="highest-wins-table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>{t(column.key)}</th>
            ))}
          </tr>
        </thead>

        {!!wins.length && (
          <tbody>
            {wins.map((item: any, index: number) => (
              <tr key={index}>
                {columns.map((column) => {
                  if (column.accessor === 'gameName') {
                    return (
                      <td>
                        <div onClick={() => onGameClick(item.gameId)} className="highest-wins-table-game-name-cell">
                          <SvgIcon
                            src={MediaManager.getSrcFromMediaAssets('burgerMenuIcon.svg', '/navigation-icons/')}
                            className="highest-wins-table-game-icon"
                          />
                          <div className="highest-wins-table-game-name">{item[column.accessor]}</div>
                        </div>
                      </td>
                    );
                  }

                  if (column.accessor === 'date') {
                    return <td>{moment(item[column.accessor]).format('h:mm A')}</td>;
                  }

                  if (column.accessor === 'won') {
                    return <td>{formatCurrency(item[column.accessor], item.currency)}</td>;
                  }

                  return <td>{item[column.accessor]}</td>;
                })}
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {!wins.length && (
        <>
          <div className="highest-wins-empty">{t('no_data')}</div>
          <div className="highest-wins-separator" />
        </>
      )}
    </>
  );
};

export default HighestWinsTable;
