/* eslint-disable max-len */
import { FC, useEffect } from 'react';

const WordPressConversionPixel: FC<any> = ({ wpbType, wpbAmount, wpbCurrency, wpbProductId }) => {
  useEffect(() => {
    const l = localStorage;
    const wpbidKey = 'wpb_id';
    const key = `_blaze_${wpbidKey}`;

    // Retrieve wpbid from URL params or localStorage
    const urlParams = new URLSearchParams(window.location.search);
    const wpbid = urlParams.get(wpbidKey) || l.getItem(key);

    if (wpbid) {
      l.setItem(key, wpbid);
    }

    const sendConversion = (type: any, amount: any, currency: any, productId: any): any => {
      if (!wpbid) return;

      const src = `https://public-api.wordpress.com/wpcom/v2/wordads/dsp/pro-api/v1/conversion/pixel.gif?wpb_id=${wpbid}&wpb_advertiser=1ae1fba3b257b2fdc58f16fbb9439f03&image=true&wpb_type=${type}&wpb_amount=${amount}&wpb_currency=${currency}&wpb_product_id=${productId}`;

      const pixel = new Image();
      pixel.src = src;
      pixel.width = 1;
      pixel.height = 1;
      pixel.style.display = 'none';
      document.body.appendChild(pixel);

      l.removeItem(key);
    };

    // Run conversion tracking if valid data is available
    if (wpbType && wpbAmount && wpbCurrency && wpbProductId) {
      sendConversion(wpbType, wpbAmount, wpbCurrency, wpbProductId);
    }
  }, [wpbType, wpbAmount, wpbCurrency, wpbProductId]);

  return (
    <img
      id="wpb_id-pix"
      crossOrigin="anonymous"
      data-wpb_type={wpbType}
      data-wpb_amount={wpbAmount}
      data-wpb_currency={wpbCurrency}
      data-wpb_product_id={wpbProductId}
      style={{ display: 'none' }}
      alt="Conversion Pixel"
    />
  );
};

export default WordPressConversionPixel;
