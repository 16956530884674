import HttpClient from './http';

const getHighestWins = (skinId: number): Promise<any> => {
  return HttpClient.get({
    path: `/api/getBetWinVisualFeed?skinId=${skinId}`,
    baseURL: process.env.REACT_APP_REPORTS as string,
  });
};

const getBiggestWins = (hierarchyId: number): Promise<any> => {
  return HttpClient.get({
    path: `getBetWinVisualFeed?hierarchyId=${hierarchyId}&tag=biggest`,
    baseURL: process.env.REACT_APP_BC_API_V3 as string,
  });
};

const getLatestWins = (hierarchyId: number): Promise<any> => {
  return HttpClient.get({
    path: `getBetWinVisualFeed?hierarchyId=${hierarchyId}&tag=latest`,
    baseURL: process.env.REACT_APP_BC_API_V3 as string,
  });
};

export { getBiggestWins, getHighestWins, getLatestWins };
