import cn from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Typography } from 'src/components/common/Typography';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { setCloseUserSettings } from 'src/store/app/actions';
import { RootState } from 'src/types/store-types';
import { Button } from '../../common/Button';
import PopUpContainer from '../../common/modal/PopUp';
import './styles.scss';

const GAMEBOX_WIDGET = 'gamebox-widget';

type Props = {
  settings: {
    needLogin: boolean;
    priority: number;
    styles: Styles;
    classes: string;
  };
};
const GameBoxToggle: FC<Props> = ({ settings }) => {
  const { t }: Translation = useTranslation();

  const { styles, classes } = settings;
  const sessionRecorder = useSessionRecorder();

  const _setCloseUserSettings = useConnectedAction(setCloseUserSettings);

  const { kycStatus } = useSelector((state: RootState) => state.user);
  const { isGameBoxOpen, showUserSetting } = useSelector((state: RootState) => state.app);
  const { actualMissions } = useSelector((state: RootState) => state.integrations);
  const [isPopUpOpen, setIsPopUpOpen] = useState<boolean>(false);
  const isKycEnable = process.env.REACT_APP_KYC_ENABLE === 'true';

  const missionsCount = useMemo(() => {
    if (actualMissions === 0) return;

    return <span className="actual_missions">{actualMissions}</span>;
  }, [actualMissions]);

  const onToggle = (): void => {
    sessionRecorder?.trackEvent('Header "gamebox" button click');
    isMobileOnly && setBodyOverflow('unset');
    window.__gdlib?.openGameBoxWidget({
      parentId: GAMEBOX_WIDGET,
      customClassName: `frame`,
      kycStatus: isKycEnable ? kycStatus : null,
    });
  };

  useEffect(() => {
    if (isGameBoxOpen) {
      onToggle();
      showUserSetting && _setCloseUserSettings();
    }

    isMobileOnly && setBodyOverflow(isGameBoxOpen ? 'unset' : 'set');
  }, [isGameBoxOpen]);

  const closePopUp = (): void => {
    setIsPopUpOpen(false);
  };

  return (
    <>
      {isPopUpOpen && (
        <PopUpContainer
          isOpen={isPopUpOpen}
          hasOverlay
          handleClose={closePopUp}
          handleOK={closePopUp}
          description={t('gameSlowText')}
        />
      )}
      <div className="gameBox_toggle_wrapper" onClick={() => onToggle()}>
        <Button
          ariaLabelledby="gameBoxWidget"
          fontWeight="bold"
          variant="contained"
          color="transparent"
          className={cn('toggle_wrapper', {
            [classes]: !!classes,
            opened: isGameBoxOpen,
          })}
          style={styles}
        >
          <img src={`/images/gbIcon.png`} alt="gameBox" />
        </Button>
        <Typography className="label" variant={'h6'}>
          {missionsCount}
        </Typography>
      </div>
    </>
  );
};
export default GameBoxToggle;
