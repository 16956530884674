import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSkinId, setParams } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { gameUrlRequest } from 'src/services/gameApi';
import { openMyAccountModal } from 'src/store/account-modal/actions';
import { setGameBoxWidgetClose, setSelectedCategory } from 'src/store/app/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { setSelectedGame, setSelectedProvider, setSelectedTag } from 'src/store/games/actions';
import { forceCloseGameBox, setFreeSpinGames, setSelectedTounamentGame } from 'src/store/integrations/actions';
import { setBalance } from 'src/store/user/actions';
import { userInitialState } from 'src/store/user/reducer';
import { GameParamType } from 'src/types/req-dto/game';
import { RootState } from 'src/types/store-types';
import { GAMEBOX_WIDGET } from 'src/utils/constants';
import { useSessionRecorder } from './use-session-recorder';

const useGameBox = (): void => {
  const sessionRecorder = useSessionRecorder();
  const { t } = useTranslation();
  const { locale } = useI18n();

  const navigate = useNavigate();

  const { game, selectedTag } = useSelector((state: RootState) => state.games);
  const { user } = useSelector((state: RootState) => state.user);

  const skinId = getSkinId();

  const _openDialog = useConnectedAction(openDialog);
  const _requestBalance = useConnectedAction(setBalance.req);
  const _freeSpinGamesReq = useConnectedAction(setFreeSpinGames.req);
  const _forceCloseGameBox = useConnectedAction(forceCloseGameBox);
  const _selectedGame = useConnectedAction(setSelectedGame);
  const _setGameBoxWidgetClose = useConnectedAction(setGameBoxWidgetClose);
  const _setSelectedTounamentGame = useConnectedAction(setSelectedTounamentGame);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _setSelectedTag = useConnectedAction(setSelectedTag);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);

  const closeGameBoxByGameInitiative = (): void => {
    _requestBalance();

    if (selectedTag === 'free_rounds' && userInitialState) {
      _freeSpinGamesReq();
    }
  };

  const getGameUrl = async (gameId: number, walletId: string): Promise<any> => {
    const params: GameParamType = setParams(user, { id: gameId }, skinId, locale, isMobile, walletId);

    try {
      return await gameUrlRequest(params);
    } catch (e: any) {
      const msg = t(`game_error.${e?.message === 'Network Error' ? 'network_error' : 'unavailable'}`, { gameId });
      console.log(msg);
      //   if (iframe) {
      //     iframe?.postMessage(
      //       {
      //         type: 'GAME_OPEN_FAILURE',
      //         gameId,
      //         msg,
      //       },
      //       '*'
      //     );
      //   }

      if (!e?.response?.data.success) {
        _forceCloseGameBox(true);
      }

      closeGameBoxByGameInitiative();
    }
  };

  const closeGameBox = (): void => {
    const iframe = document.getElementById(GAMEBOX_WIDGET);
    const queryParams = new URLSearchParams(window?.location?.search);
    queryParams.delete('gameBox_category');
    const queryString = queryParams.toString();
    const newPath = queryString ? `${location.pathname}?${queryString}` : location.pathname;
    if (iframe) {
      iframe.style.cssText =
        'width: 0;height: 0; top: 0; position: fixed; border: none; right: 0;z-index: 150;transition: height 0.2s; display: block;';
    }
    document.body.removeAttribute('style');
    _selectedGame(null);
    navigate(newPath, { replace: true });
    _setGameBoxWidgetClose();
    closeGameBoxByGameInitiative();
  };
  const closeGameBoxGlobalWidget = (): void => {
    _setGameBoxWidgetClose();
  };

  const getGameUrlStr = (): void => {
    window.__gdlib?.openGameBoxWidget({
      parentId: GAMEBOX_WIDGET,
      type: 'openGameMode',
    });
    getGameUrl(+game?.id, game?.gameWalletId).then((res) => {
      if (res?.success && user && locale) {
        const gameResponse = res.result.data;

        window.__gdlib?.openGameBoxWidget({
          parentId: GAMEBOX_WIDGET, // maybe need to change parent id => parent name
          customClassName: `frame`,
          type: 'openGameMode',
          gameUrl: gameResponse.url,
          gameInfo: game,
        });
      } else {
        _openDialog({
          dialogType: EnumDialogsKeys.GAME_URL_FAILURE,
          dialogProps: { data: 'gameUrlFailure' },
        });
      }
    });
  };
  const openTournamentGame = (data: any): void => {
    navigate('/category/casinoTournament');
    _setSelectedTounamentGame(data);
    _setSelectedCategory('casinoTournament');
  };
  const openfreeRoundsPage = (): void => {
    navigate('/category/casino');
    _setSelectedCategory('casino');
  };
  const navigateToCategory = (payload: { category: string; params?: any }): void => {
    if (payload.params && !isNaN(payload.params)) {
      _setSelectedCategory(payload.category);
      localStorage.setItem('missionId', payload.params);
      _setSelectedTag('home');
      navigate(`/category/${payload.category}`);
    } else if (payload.category) {
      _setSelectedProvider(['all_providers']);
      _setSelectedCategory(payload.category);
      _setSelectedTag('home');
      navigate(`/category/${payload.category}`);
    }
  };

  useEffect(() => {
    if (user && game) {
      getGameUrlStr();
    }
  }, [user, game]);
  useEffect(() => {
    window.__gdlib?.registerCallBack('closeGameBox', closeGameBox);
    window.__gdlib?.registerCallBack('closeGameBoxGlobalWidget', closeGameBoxGlobalWidget);
    window.__gdlib?.registerCallBack('openTournamentGame', openTournamentGame);
    window.__gdlib?.registerCallBack('openfreeRoundsPage', openfreeRoundsPage);
    window.__gdlib?.registerCallBack('openDepositModal', () =>
      _openAccountModal({ section: 'deposit', subSection: 'deposit' })
    );
    window.__gdlib?.registerCallBack('funBonusSetGameUrl', (data: any) => {
      window.__gdlib?.openGameBoxWidget({
        parentId: GAMEBOX_WIDGET,
        customClassName: `frame`,
        type: 'openGameMode',
        gameUrl: data.gameUrl,
        gameInfo: data.gameInfo,
      });
    });
    window.__gdlib?.registerCallBack('navigateToCategory', navigateToCategory);
    window.__gdlib?.registerCallBack(
      'gameBoxChangeCategoryTriggered',
      (data: { navigation: 'vertical' | 'horizontal' }) => {
        data.navigation === 'vertical'
          ? sessionRecorder?.trackEvent('Gamebox vertical category change')
          : sessionRecorder?.trackEvent('Gamebox horizontal category change');
      }
    );
    window.__gdlib?.registerCallBack('gameBoxRaceRegistrationTriggered', () => {
      sessionRecorder?.trackEvent('Gamebox race registration');
    });
    window.__gdlib?.registerCallBack('gameBoxCasinoFreeSpinTriggered', () => {
      sessionRecorder?.trackEvent('Gamebox casino free spin click');
    });
  }, []);

  useEffect(() => {
    if (locale) {
      window.__gdlib?.changeLanguage(locale);
    }
  }, [locale]);
};

export default useGameBox;
