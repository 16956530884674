import { FC, useEffect } from 'react';
import { isDesktop, isMobileOnly } from 'react-device-detect';

const TawkChat: FC = () => {
  useEffect(() => {
    const setZIndex = (zIndex: string): void => {
      const tawkWidget = document.querySelector('.widget-visible') as HTMLElement;
      if (tawkWidget && isDesktop) {
        tawkWidget.style.setProperty('z-index', zIndex, 'important');
      }
    };

    const handleWidget = (): void => {
      if ((window as any)?.Tawk_API && Object.keys((window as any)?.Tawk_API).length) {
        if (isMobileOnly) {
          (window as any)?.Tawk_API?.hideWidget();
        } else {
          (window as any)?.Tawk_API?.showWidget();
          setZIndex('115');
        }
      }
    };

    // Observer to detect when Tawk.to widget appears in the DOM
    const observer = new MutationObserver(() => {
      const tawkWidget = document.querySelector('.widget-visible');
      if (tawkWidget) {
        handleWidget();
        observer.disconnect(); // Stop observing after the widget is found
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      if ((window as any)?.Tawk_API && Object.keys((window as any)?.Tawk_API).length) {
        (window as any)?.Tawk_API?.showWidget();
        setZIndex('115');
      }
    };
  }, []);

  return null;
};

export default TawkChat;
