import { FC } from 'react';
import { useSelector } from 'react-redux';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
import ActivityPopup from './ActivityPopup';
import BadGeoLocationPopup from './BadGeoLocationPopup';
import CookiesPopup from './CookiesPopup';
import DeviceCompatibilityPopup from './DeviceCompatibilityPopup';
import FinalTourInfo from './FinalTourInfo';
import FirstDepositToBonusWallet from './FirstDepositToBonusWallet';
import FreeSpins from './FreeSpins';
import GameLaunchPreview from './GameLaunchPreview';
import GameUrlFailure from './GameUrlFailure/GameUrlFailure';
import KycPopup from './KycPopup';
import LimitExceedAlertPopup from './LimitsExceedPopup/LimitExceedAlertPopup';
import LimitExceedBlockPopup from './LimitsExceedPopup/LimitExceedBlockPopup';
import LimitsPopup from './LimitsPopup';
import OpenNewTab from './OpenNewTab';
import OtpPopup from './OtpPopup';
import Player2StepPopup from './Player2StepPopup';
import RegionPopup from './RegionPopup';
import RegistrationFailure from './RegistrationFailure';
import ResetPasswordSuccess from './ResetPasswordSuccess';
// import ResumeTournament from './ResumeTournament';
import SpinAndGoStarted from './SpinAndGoStarted';
import SuccessFullyReged from './SuccessFullyReged';
import TournamentResults from './TournamentResults';
import TransactionCancel from './TransactionCancel';
import TransactionInfo from './TransactionInfo';

const Dialogs: FC = () => {
  const dialogs = useSelector((state: RootState) => state.dialog.dialogs);

  const dialogComponents = {
    [EnumDialogsKeys.RESET_PASSWORD_SUCCESS]: () => (
      <ResetPasswordSuccess
        dialogCase={dialogs[EnumDialogsKeys.RESET_PASSWORD_SUCCESS]?.dialogCase}
        email={dialogs[EnumDialogsKeys.RESET_PASSWORD_SUCCESS]?.email}
      />
    ),
    [EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS]: () => (
      <ResetPasswordSuccess
        dialogCase={dialogs[EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS]?.dialogCase}
        email={dialogs[EnumDialogsKeys.CHANGE_PASSWORD_SUCCESS]?.email}
        isChangePassword
      />
    ),
    /* {dialogs?.[EnumDialogsKeys.RESUME_TOURNAMENT] && (
        <ResumeTournament data={dialogs[EnumDialogsKeys.RESUME_TOURNAMENT]?.data} />
      )} */
    [EnumDialogsKeys.TOURNAMENT_RESULTS]: () => (
      <TournamentResults data={dialogs[EnumDialogsKeys.TOURNAMENT_RESULTS]?.data} />
    ),
    [EnumDialogsKeys.FINAL_TOUR_INFO]: () => <FinalTourInfo data={dialogs[EnumDialogsKeys.FINAL_TOUR_INFO]?.data} />,
    [EnumDialogsKeys.SECCESSFULLY_REGED]: () => <SuccessFullyReged />,
    [EnumDialogsKeys.SPIN_AND_GO_STARTED]: () => (
      <SpinAndGoStarted data={dialogs[EnumDialogsKeys.SPIN_AND_GO_STARTED]?.data} />
    ),
    [EnumDialogsKeys.REGISTRATION_FAILURE]: () => (
      <RegistrationFailure data={dialogs[EnumDialogsKeys.REGISTRATION_FAILURE]?.data} />
    ),
    [EnumDialogsKeys.FREE_SPINS]: () => <FreeSpins data={dialogs[EnumDialogsKeys.FREE_SPINS]?.data} />,
    [EnumDialogsKeys.GAME_LAUNCH_PREVIEW]: () => (
      <GameLaunchPreview data={dialogs[EnumDialogsKeys.GAME_LAUNCH_PREVIEW]?.data} />
    ),
    [EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET]: () => (
      <FirstDepositToBonusWallet data={dialogs[EnumDialogsKeys.FIRST_DEPOSIT_TO_BONUS_WALLET]?.data} />
    ),
    [EnumDialogsKeys.MAIN_TO_POKER_TRANSACTION]: () => (
      <TransactionInfo data={dialogs[EnumDialogsKeys.MAIN_TO_POKER_TRANSACTION]?.data} />
    ),
    [EnumDialogsKeys.GAME_URL_FAILURE]: () => <GameUrlFailure data={dialogs[EnumDialogsKeys.GAME_URL_FAILURE]?.data} />,
    [EnumDialogsKeys.ACTIVITY_POPUP]: () => <ActivityPopup data={dialogs[EnumDialogsKeys.ACTIVITY_POPUP]?.data} />,
    [EnumDialogsKeys.LIMITS_POPUP]: () => <LimitsPopup data={dialogs[EnumDialogsKeys.LIMITS_POPUP]?.data} />,
    [EnumDialogsKeys.DINHU_ANALIZER]: () => <OpenNewTab data={dialogs[EnumDialogsKeys.DINHU_ANALIZER]?.data} />,
    [EnumDialogsKeys.COOKIES_POPUP]: () => <CookiesPopup />,
    [EnumDialogsKeys.KYC_POPUP]: () => <KycPopup />,
    [EnumDialogsKeys.REGION_POPUP]: () => <RegionPopup />,
    [EnumDialogsKeys.OTP_REQUEST_POPUP]: () => <OtpPopup email={dialogs[EnumDialogsKeys.OTP_REQUEST_POPUP]?.data} />,
    [EnumDialogsKeys.BAD_GEOLOCATION]: () => (
      <BadGeoLocationPopup data={dialogs[EnumDialogsKeys.BAD_GEOLOCATION]?.data} />
    ),
    [EnumDialogsKeys.DEVICE_COMPATIBILITY_POPUP]: () => <DeviceCompatibilityPopup />,
    [EnumDialogsKeys.PLAYER_2_STEP]: () => <Player2StepPopup data={dialogs[EnumDialogsKeys.PLAYER_2_STEP]?.data} />,
    [EnumDialogsKeys.LIMITS_EXCEED_ALERT]: () => (
      <LimitExceedAlertPopup data={dialogs[EnumDialogsKeys.LIMITS_EXCEED_ALERT]?.data} />
    ),
    [EnumDialogsKeys.LIMITS_EXCEED_BLOCK]: () => (
      <LimitExceedBlockPopup data={dialogs[EnumDialogsKeys.LIMITS_EXCEED_BLOCK]?.data} />
    ),
    [EnumDialogsKeys.TRANSACTION_CANCEL]: () => (
      <TransactionCancel data={dialogs[EnumDialogsKeys.TRANSACTION_CANCEL]?.data} />
    ),
  };

  return (
    <>{Object.entries(dialogComponents).map(([key, Component]) => dialogs?.[key as EnumDialogsKeys] && Component())}</>
  );
};

export default Dialogs;
