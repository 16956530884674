import React, { FC } from 'react';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';

type Props = {
  data: any;
};

const TransactionCancel: FC<Props> = ({ data }) => {
  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    _closeDialog({ dialogType: EnumDialogsKeys.TRANSACTION_CANCEL });
  };

  return (
    <PopUpContainer
      isOpen
      hasOverlay={false}
      title={data.message}
      type="default"
      handleClose={removeDialog}
      handleOK={removeDialog}
      zIndex={999999999999999}
    />
  );
};

export default TransactionCancel;
