import { call, put, takeLatest } from 'redux-saga/effects';
import { getFinalTourInfo } from 'src/services/dataApi';
import { detectDevice } from '../../helpers/utils';
import { openDialog } from '../dialog/actions';
import { EnumDialogsKeys } from '../dialog/types';
import { setFinalTourInfo } from './actions';
import { APP_SET_FINAL_TOUR_INFO_REQ } from './types';

// final tour info
function* setFinalTourInfoSaga({ payload }: any): any {
  try {
    const response = yield call(getFinalTourInfo, payload);
    const device = detectDevice();
    if (response.data?.length) {
      yield put(setFinalTourInfo.success());
      const parsedPopupIds: number[] = JSON.parse(localStorage.getItem('dontShowPopupIds') || '[]') as [];
      const existsDialog = response.data.some(
        (item: { id: number; attributes: { Device: { type: string } } }) =>
          parsedPopupIds.includes(item.id) && item.attributes.Device.type === device
      );

      if (Array.isArray(parsedPopupIds) && !existsDialog) {
        yield put(openDialog({ dialogType: EnumDialogsKeys.FINAL_TOUR_INFO, dialogProps: { data: response.data } }));
      }
    }
  } catch (error: any) {}
}

export function* watchApp(): any {
  yield takeLatest(APP_SET_FINAL_TOUR_INFO_REQ, setFinalTourInfoSaga);
}
