import { ChangeEvent, FC, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import { openMyAccountModal } from '../../../store/account-modal/actions';
import { Checkbox } from '../Checkbox';

type IProps = {
  section: string;
  subSection: string;
  onChange: (e: any) => void;
  fullHeight: boolean;
  id: string;
};
const TermsConditionsPolicy: FC<IProps> = ({ onChange, id, section, subSection, fullHeight }) => {
  // Hooks
  const { t }: Translation = useTranslation();
  const [termsConditionsCheckbox, setTermsConditionsCheckbox] = useState<boolean>(false);

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  // YUP Validation
  const validationSchema = Yup.object().shape({
    termsConditionsPolicy: Yup.boolean().oneOf([true], t('requiredField')).required(t('requiredField')),
  });

  const openInfoAndRulesModal = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    _openAccountModal({ section, subSection, fullHeight });
  };

  const termsConditionsPolicy = useMemo(() => {
    return (
      <>
        {t('eligibilityDeclaration')}
        <span className="terms_and_conditions_checkbox__link" onClick={(e) => openInfoAndRulesModal(e)}>
          {t('privacyPolicy')}
        </span>
        {t('termsConditionsAgreement')}
      </>
    );
  }, []);

  const handleValidate = async (value: boolean): Promise<void> => {
    try {
      await validationSchema.validate({ termsConditionsPolicy: value }, { abortEarly: false });
      resetSignUpError('termsConditionsPolicy');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, termsConditionsPolicy: error.errors[0] });
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setTermsConditionsCheckbox(e.target.checked);
    handleValidate(e.target.checked).catch((e) => console.log(e));
    if (signUpErrors.termsConditionsPolicy) {
      resetSignUpError('termsConditionsPolicy');
    }
    onChange(e);
  };

  return (
    <Checkbox
      error={signUpErrors.termsConditionsPolicy}
      onChange={handleOnChange}
      label={termsConditionsPolicy}
      id={id}
      name="termsConditionsPolicy"
      value={termsConditionsCheckbox}
    />
  );
};
export default TermsConditionsPolicy;
