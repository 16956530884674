import History from './sections/History';
import InfoAndRulesSection from './sections/InfoAndRulesSection';
import Kyc from './sections/Kyc';
import ActivityLimitations from './sections/Limitations/ActivityLimitations';
import BetLimitations from './sections/Limitations/BetLimitations';
import DepositLimitations from './sections/Limitations/DepositLimitations';
import LossLimitations from './sections/Limitations/LossLimitations';
import AccountDetails from './sections/MyAccount/AccountDetails';
import ChangePassword from './sections/MyAccount/ChangePassword';
import ClosureAccount from './sections/MyAccount/CloseAccount';
import LoginHistory from './sections/MyAccount/LoginHistory';
import Payments from './sections/Payments';
import PendingHistory from './sections/PendingHistory';
import ReferAFriend from './sections/ReferAFriend/ReferAFriend';

const Views: any = {
  info_and_rules: InfoAndRulesSection,
  cashier: Payments,
  deposit: Payments,
  withdrawel: Payments,
  my_account: AccountDetails,
  personal_info: AccountDetails,
  finance_history: History,
  transactions: History,
  casino_history: History,
  pending_history: PendingHistory,
  change_password: ChangePassword,
  login_history: LoginHistory,
  close_account: ClosureAccount,
  referAFriend: ReferAFriend,
  limits: DepositLimitations,
  depositLimits: DepositLimitations,
  activityLimits: ActivityLimitations,
  betLimits: BetLimitations,
  lossLimits: LossLimitations,
  kyc: Kyc,
};

export default Views;
