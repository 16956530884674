import { envStrToBoolean } from './utils';

export const getModuleFrames = (): string[] => {
  const moduleFrames = [];

  if (envStrToBoolean(process.env.REACT_APP_GOS_FRAME)) moduleFrames.push('casinoTournament');
  if (envStrToBoolean(process.env.REACT_APP_RACE_FRAME)) moduleFrames.push('race');
  if (envStrToBoolean(process.env.REACT_APP_FUN_MISSION_FRAME)) moduleFrames.push('fun-mission');

  return moduleFrames;
};
