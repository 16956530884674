/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/no-duplicate-string */
import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { Loader } from 'src/components/common/Loader';
import { formatCurrency, removeFromArray } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { cancelPendingTransaction, getPendingTransaxtions } from 'src/services/userApi';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { RootState } from 'src/types/store-types';
import './styles.scss';

const PendingHistory: FC = () => {
  const { t } = useTranslation();
  const { subSection } = useSelector((state: RootState) => state.accountModal);

  const [transactionData, setTransactionData] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const _openDialog = useConnectedAction(openDialog);

  useEffect(() => {
    setTransactionData([]);
    getPendingTransactions();
  }, [subSection]);

  const getPendingTransactions = async (): Promise<void> => {
    const res = await getPendingTransaxtions();
    setIsLoading(false);
    if (res.success) {
      setTransactionData(res.result);
    }
  };

  const cancelPendingTransactionReq = async (id: string): Promise<void> => {
    const res = await cancelPendingTransaction(id);
    if (res.success) {
      const newResult: any = removeFromArray(transactionData, (item) => item.transactionId === id);
      setTransactionData(newResult);
      _openDialog({
        dialogType: EnumDialogsKeys.TRANSACTION_CANCEL,
        dialogProps: { data: { message: t('successfullyCancelPendingRequest') } },
      });
    }
  };

  const memorisedData = useMemo(() => {
    return isMobileOnly ? (
      <div className="table_wrapper">
        <div className="table_container">
          {transactionData?.length ? (
            <div className="entries_table_list">
              {transactionData.map((transaction: any) => (
                <div className="list" key={transaction._id}>
                  <div className="entries_table_caption">
                    <div className="entries_table_caption__item movement pending-movement">{t('amount')}:</div>
                    <div className="entries_table_caption__item movement pending-movement">{t('transactionId')}:</div>
                    <div className="entries_table_caption__item movement pending-movement">{t('date')}:</div>
                    <div className="entries_table_caption__item movement pending-movement">{t('action')}:</div>
                  </div>
                  <div className="data_row movement pending-movement">
                    <span className={'table_container__item'}>
                      {formatCurrency(transaction.amount, transaction.currency, undefined, false)}
                    </span>
                    <span className={'table_container__item'}>ID: {transaction.transactionId}</span>
                    <span>{moment(transaction.dateCreated).format('DD MMM YYYY, hh:mm:ss') ?? '-'}</span>
                    <div className={'table_container__item'}>
                      <Button
                        className="cancel-btn"
                        onClick={() => cancelPendingTransactionReq(transaction.transactionId)}
                      >
                        {t('cancel')}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="loader">
              <div className="loader">
                {isLoading ? <Loader /> : <span className="empty">{t('dontHavePendingWitdrawals')}</span>}
              </div>
            </div>
          )}
        </div>
      </div>
    ) : (
      <div className="table_wrapper">
        <div className="table_container">
          <div className="entries_table_caption">
            <div className="entries_table_caption__item movement pending-movement">{t('amount')}:</div>
            <div className="entries_table_caption__item movement pending-movement">{t('transactionId')}:</div>
            <div className="entries_table_caption__item movement pending-movement">{t('date')}:</div>
            <div className="entries_table_caption__item movement pending-movement">{t('action')}:</div>
          </div>
          {transactionData?.length ? (
            <div className="entries_table_list">
              {transactionData.map((transaction: any) => (
                <div key={transaction._id} className="data_row movement pending-movement">
                  <span className={'table_container__item'}>
                    {formatCurrency(transaction.amount, transaction.currency, undefined, false)}
                  </span>
                  <span className={'table_container__item'}>ID: {transaction.transactionId}</span>
                  <span>{moment(transaction.dateCreated).format('DD MMM YYYY, hh:mm:ss') ?? '-'}</span>
                  <div className={'table_container__item'}>
                    <Button
                      className="cancel-btn"
                      onClick={() => cancelPendingTransactionReq(transaction.transactionId)}
                    >
                      {t('cancel')}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="loader">
              {isLoading ? <Loader /> : <span className="empty">{t('dontHavePendingWitdrawals')}</span>}
            </div>
          )}
        </div>
      </div>
    );
  }, [transactionData, isLoading]);

  return <div className="transaction_history_wrapper">{memorisedData}</div>;
};
export default PendingHistory;
