import { SessionRecorder, SessionRecorderEvents } from 'src/types/services/SessionRecorder';

export class SessionRecordingService {
  public isLoaded = false;
  public isClarityLoaded = false;
  public isPosthogLoaded = false;
  public recorders: Record<'clarityRecorder' | 'posthogRecorder', SessionRecorder | null> = {
    clarityRecorder: null,
    posthogRecorder: null,
  };

  public identifyUser = async (id: string, username: string): Promise<void> => {
    if (!this.isLoaded) {
      await this.loadRecorders();
    }

    Object.values(this.recorders).forEach((recorder) => {
      recorder?.identifyUser({ id, username });
    });
  };

  public trackEvent = async (eventName: SessionRecorderEvents, data?: Record<string, any>): Promise<void> => {
    if (!this.isLoaded) {
      await this.loadRecorders();
    }

    Object.values(this.recorders).forEach((recorder) => {
      recorder?.trackEvent(eventName, data);
    });
  };

  public loadPosthogRecorder = async (): Promise<void> => {
    if (this.isPosthogLoaded) return;

    const posthogModule = await import('../services/posthogRecorder');

    this.recorders.posthogRecorder = new posthogModule.PosthogRecorder();
    this.isPosthogLoaded = true;
  };

  public loadClarityRecorder = async (): Promise<void> => {
    if (this.isClarityLoaded) return;

    const clarityModule = await import('../services/clarityRecorder');

    this.recorders.clarityRecorder = new clarityModule.ClarityRecorder();
    this.isClarityLoaded = true;
  };

  public loadRecorders = async (): Promise<void> => {
    if (this.isLoaded) return;

    await Promise.all([this.loadClarityRecorder(), this.loadPosthogRecorder()]);

    this.isLoaded = true;
  };
}

export const sessionRecordingService = new SessionRecordingService();
