import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import _styles from '../../styles.module.scss';
import PrizeDistribution from '../PrizeDistribution/PrizeDistribution';
import Steps from '../Steps/Steps';

type Props = {
  closeTermsAndConditions: (show: boolean) => void;
};
const HowItWorks: FC<Props> = ({ closeTermsAndConditions }) => {
  const { t } = useTranslation();
  const refFriendAccessibility = process.env.REACT_APP_REF_FRIEND_ACCESSIBILITY !== 'true';

  return (
    <div className={_styles.how_it_works_container_modal}>
      <div className={_styles.header}>
        <div className={_styles.title_wrapper}>
          <Typography variant={'h4'} className={_styles.title}>
            {t('refer_a_friend.how_it_works')}
          </Typography>
          <span className={_styles.text}>{t(`refer_a_friend.how_it_works_text`)}</span>
        </div>
        <div className={_styles.close}>
          <SvgIcon src={icons.close} onClick={() => closeTermsAndConditions(false)} />
        </div>
      </div>
      <div className={_styles.content}>
        {refFriendAccessibility && <Steps />}
        <PrizeDistribution />
      </div>
    </div>
  );
};
export default HowItWorks;
