import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

const RaceView = (): JSX.Element => {
  const isRaceLoggedIn = useRef(false);
  const RaceSDK = useRef<RaceSDK>();
  const { locale } = useI18n();
  const navigate = useNavigate();
  const sessionRecorder = useSessionRecorder();
  const { user } = useSelector((state: RootState) => state.user);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const [fullScreen] = useState(false);

  const registerListeners = (): void => {
    RaceSDK.current?.addEventListener('CHANGE_CATEGORY', (event: { type: string; category: string }) => {
      _setSelectedCategory(event.category);
      navigate(`/category/${event.category}`);
    });

    RaceSDK.current?.addEventListener('OPEN_LOGIN', () => {
      setBodyOverflow('unset');
      _openAuthModal('login');
    });
  };

  const authorizeUser = useCallback((): void => {
    if (isRaceLoggedIn.current && !user) {
      RaceSDK.current?.actions?.handleGDLogoutAction();
      isRaceLoggedIn.current = true;
      return;
    }

    if (!isRaceLoggedIn.current && user) {
      RaceSDK.current?.actions?.handleGDLoginAction({ ...user, userId: user.id });
      isRaceLoggedIn.current = false;
    }
  }, [user]);

  const mountRaceApp = (): void => {
    setBodyOverflow('set');

    if (!window.__gdlib?.__RaceSDK) return;

    if (window.__gdlib.__RaceSDK?.init) {
      window.__gdlib.__RaceSDK.init().then(() => {
        RaceSDK.current = window.__gdlib?.__RaceSDK.instance;
        RaceSDK.current?.mount({
          onReady: () => {
            registerListeners();
            authorizeUser();
          },
        });
      });
      return;
    }

    const __RaceSDKProxy = new Proxy(window.__gdlib.__RaceSDK, {
      set(target: any, prop, newValue) {
        target[prop] = newValue;
        if (prop === 'init' && typeof newValue === 'function') {
          newValue().then(() => {
            RaceSDK.current = target.instance;
            RaceSDK.current?.mount({
              onReady: () => {
                registerListeners();
                authorizeUser();
              },
            });
          });
        }
        return true;
      },
    });

    window.__gdlib.__RaceSDK = __RaceSDKProxy;
  };

  const handleLangChange = useCallback(() => {
    RaceSDK.current?.actions.handleLanguageChange(locale);
  }, [locale]);

  useEffect(() => {
    mountRaceApp();

    return () => {
      RaceSDK.current?.unMount();
    };
  }, []);

  useEffect(() => {
    if (RaceSDK.current) {
      authorizeUser();
    }
  }, [authorizeUser]);

  useEffect(() => {
    if (!RaceSDK.current?.actions) return;

    handleLangChange();
  }, [handleLangChange]);

  useEffect(() => {
    sessionRecorder?.trackEvent('Race page view');
  }, []);

  return (
    <div className={cn('page_container', 'raceView_Wrapper', { ['raceView_Wrapper--mobile']: isMobile })}>
      <div className={cn('raceView_Container', { ['fullScreen']: fullScreen })} id="__RACE__" />
    </div>
  );
};

export default RaceView;
