import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getSkinId, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { gameUrlRequest } from 'src/services/gameApi';
import { setSelectedCategory } from 'src/store/app/actions';
import { RootState } from 'src/types/store-types';
import { EnumLangCodes } from 'src/utils/constants';

const Cms_Wager: FC<{ sportType: 'live' | 'sport' }> = ({ sportType }) => {
  const { locale } = useI18n();
  const skinId = getSkinId();
  const { user } = useSelector((state: RootState) => state.user);
  const { categoriesData } = useSelector((state: RootState) => state.configs);
  const navigate = useNavigate();

  setBodyOverflow('unset');

  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  const [data, setData] = useState<any>(null);

  // Fetch game URL data.
  const getSportGame = async (): Promise<void> => {
    const game = categoriesData?.betting?.tags[0]?.tagGames[0];
    if (game) {
      const payload: any = {
        // id: game.id,
        id: 45311,
        skinId,
        lang: locale,
        device: isMobile ? 'mobile' : 'desktop',
      };
      if (user) {
        payload.userId = user.id;
        payload.token = user.token;
      }
      const res: any = await gameUrlRequest(payload);
      if (res.result) {
        setData(res.result.data);
      } else {
        console.log(`get game url error ${game.id}`);
      }
    }
  };

  useEffect(() => {
    getSportGame();
  }, [user, categoriesData, skinId, locale]);

  useEffect(() => {
    if (cmsSportbook && data) {
      const deviceType = isMobile ? 'mobile' : 'desktop';
      const userStatus = user ? data.token : 'guest';
      cmsSportbook.startSportbook(deviceType, userStatus, EnumLangCodes[locale], data?.clientKey, sportType);
    }
  }, [user, locale, data, sportType]);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.action === 'redirectTo') {
        setTimeout(() => {
          setBodyOverflow('set');
        }, 500);

        switch (event.data.category) {
          case 'casino':
            _setSelectedCategory('casino');
            navigate('/category/casino');
            break;
          case 'livecasino':
            _setSelectedCategory('liveCasino');
            navigate('/category/liveCasino');
            break;
          default:
            _setSelectedCategory('home');
            navigate('/');
        }
      }
    });
  }, []);

  return <div id="appcontent"></div>;
};

export default Cms_Wager;
