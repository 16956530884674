import cn from 'classnames';
import { FC, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components/common/Button';
import GameCard from 'src/components/common/GameCard';
import { TEMPLATE_1_SLIDER_GAMES_COUNT } from 'src/utils/constants';
import '../styles.scss';
import { LeftArrow, RightArrow } from './Arrows';

type Props = {
  activeGames: any;
  viewAllGamesOfTag?: any;
  tagName: string;
  showArrows?: boolean | undefined;
  showViewAllLink?: boolean;
};
const Template1: FC<Props> = ({ activeGames, viewAllGamesOfTag, tagName }) => {
  const { t }: Translation = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  const [left, setLeft] = useState<number>(TEMPLATE_1_SLIDER_GAMES_COUNT);

  //TO DO ADD 161 AS SKIN VAR
  const onPrev = (): void => {
    setLeft((prev) => prev - TEMPLATE_1_SLIDER_GAMES_COUNT);

    (ref as any).current.scrollTo({
      left: (ref as any)?.current?.scrollLeft - TEMPLATE_1_SLIDER_GAMES_COUNT * 161,
      behavior: 'smooth',
    });
  };

  const onNext = (): void => {
    setLeft((prev) => prev + TEMPLATE_1_SLIDER_GAMES_COUNT);

    (ref as any).current.scrollTo({
      left: (ref as any)?.current?.scrollLeft + TEMPLATE_1_SLIDER_GAMES_COUNT * 161,
      behavior: 'smooth',
    });
  };

  return (
    <div className={cn('game_container', 'template_1')}>
      <div className="game_container_header">
        <div className="game_container_header_title_with_arrows">
          <Button ariaLabelledby="arrow" type="button" onClick={viewAllGamesOfTag} className="tag_title">
            {t(tagName)}
          </Button>
        </div>
        <div className="game_btn_container">
          {tagName !== 'keep_playing' && (
            <Button type="button" onClick={viewAllGamesOfTag} className="viewAll success-outlined-btn">
              {t('viewAll')}
            </Button>
          )}
          {!isMobileOnly && activeGames?.length > TEMPLATE_1_SLIDER_GAMES_COUNT && (
            <div className="arrows">
              <LeftArrow
                disabled={left === TEMPLATE_1_SLIDER_GAMES_COUNT}
                placement="center"
                type="squaredDark"
                style="primary-filled-btn"
                slide={onPrev}
              />
              <RightArrow
                disabled={activeGames.length < left}
                placement="center"
                style="primary-filled-btn"
                type="squaredDark"
                slide={onNext}
              />
            </div>
          )}
        </div>
      </div>
      <div className="game_carousel_container">
        <div className="inner_carousel">
          <div className="slider_template_1" ref={ref}>
            <div className="first_col">
              <GameCard
                data={activeGames[0]}
                showDetails={false}
                size={'lg'}
                effects={{
                  hoverType: 'fullWidthImage',
                  src: '',
                  text: '',
                }}
              />
            </div>
            <div className="second_col">
              {activeGames.slice(1)?.map((carouselItemArr: any, i: number) => {
                return (
                  <GameCard
                    key={carouselItemArr.name + i}
                    data={carouselItemArr}
                    showDetails={false}
                    size={'md_m'}
                    effects={{
                      hoverType: 'fullWidthImage',
                      src: '',
                      text: '',
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template1;
