import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { icons } from 'src/configs/icons';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useBannerActions } from 'src/hooks/useBannerActions';
import PersisterService from 'src/services/PersisterService';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { RootState } from 'src/types/store-types';
import { TEMPLATE_BANNNER_RADIUS, TEMPLATE_RADIUS } from 'src/utils/constants';
import '../styles.scss';

type Props = {
  cols: number;
  rows: number;
  carouselItemData: any;
};
const CarouselSlideItem: FC<Props> = ({ carouselItemData, cols }) => {
  const { t } = useTranslation();

  const { providerAction, loginAction, registrAction, promotionAction, gameAction, categoryAction, externalUrlAction } =
    useBannerActions();

  const _openAuthModal = useConnectedAction(openAuthModal);

  const { selectedCategory } = useSelector((state: RootState) => state.app);
  const { categoriesData, generalConfigs } = useSelector((state: RootState) => state.configs);
  const { tags } = useSelector((state: RootState) => state.games);
  const { user } = useSelector((state: RootState) => state.user);

  const [tagList, setTagList] = useState<any[]>([]);

  const openPokerTab = (link?: string): void => {
    if (user && link) {
      if ((window as any)?.externalPopupLink && !(window as any)?.externalPopupLink?.closed) {
        (window as any)?.externalPopupLink?.focus();
      } else {
        if (isMobile) {
          const _: any = window.open(link, '_blank');
          (window as any).externalPopupLink = _;
        } else {
          const _: any = window.open(link, '_blank', 'width=1280,height=762');
          (window as any).externalPopupLink = _;
        }
      }
    } else {
      setBodyOverflow('unset');
      _openAuthModal('login');
    }
  };

  const redirect = (ev: any, attributes: any = null): void => {
    const redirectParams = {
      target: attributes ? attributes?.target : carouselItemData?.redirection?.target,
      param: attributes ? attributes?.targetParam : carouselItemData?.redirection?.targetParam,
    };

    attributes ? ev.stopPropagation() : ev.preventDefault();

    switch (redirectParams?.target) {
      case 'goToRegister':
        registrAction();
        break;
      case 'goToLogin':
        loginAction();
        break;
      case 'goToPromotion':
        promotionAction(redirectParams?.param);
        break;
      case 'goToProvider':
        providerAction({ provider: redirectParams?.param, tags: tags, tagList: tagList });
        break;
      case 'goToExternalUrl':
        externalUrlAction();
        break;
      case 'goToGame':
        if (window.location.href.includes('/poker')) {
          let url = '';
          if (PersisterService.hasPersistValue('POKER_URL')) {
            url = PersisterService.getPersistValue('POKER_URL');
          }
          openPokerTab(url);
        } else {
          gameAction(redirectParams?.param);
        }
        break;
      case 'goToCategory':
        categoryAction(redirectParams?.param);
        break;
      case 'goToRace':
      case 'noAction':
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    categoriesData &&
      setTagList(categoriesData[selectedCategory]?.tags?.filter((f) => f.tagName === 'allGames')?.[0].tagGames || []);
  }, [categoriesData]);

  const buttonActions = carouselItemData?.buttonActions;
  const { target, position } = buttonActions ?? {};

  if (cols !== 12) {
    return (
      <div
        onClick={(ev) => redirect(ev, null)}
        style={{
          cursor: Boolean(carouselItemData?.redirection) ? 'pointer' : 'auto',
          width: `${100 / (12 / cols)}vw`,
        }}
        className="small_bannerItem"
      >
        <img
          // src={replaceImgPath(carouselItemData?.imageSrc, `${(window.innerWidth * cols) / 12}`)}
          src={carouselItemData?.imageSrc}
          alt="Slider banner"
          style={{
            borderRadius: TEMPLATE_RADIUS[generalConfigs?.template || 'template_noFullScreen'],
          }}
        />
        <div className="small_banner_buttons_container">
          {buttonActions && target && (
            <Button key={target} className="success-filled-btn" onClick={(ev) => redirect(ev, buttonActions)}>
              <SvgIcon src={icons.arrowDown} />
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={(ev) => redirect(ev, null)}
      style={{ cursor: Boolean(carouselItemData?.redirection) ? 'pointer' : 'auto' }}
      className="bannerItem"
    >
      <img
        src={carouselItemData?.imageSrc}
        loading="lazy"
        alt="Slider banner"
        className="banner-img"
        style={{
          borderRadius: isMobileOnly ? 0 : TEMPLATE_BANNNER_RADIUS[generalConfigs?.template || 'template_noFullScreen'],
        }}
      />
      <div className={cn('banner_buttons_container', position)}>
        {buttonActions && target && (
          <Button
            key={target}
            className="banner_inner_button primary-filled-btn"
            onClick={(ev) => redirect(ev, buttonActions)}
          >
            {t('goTo')}
          </Button>
        )}
      </div>
    </div>
  );
};
export default CarouselSlideItem;
