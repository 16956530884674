import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PopUpContainer from 'src/components/common/modal/PopUp/Popup';
import { checkDeviceCompatibility, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';

interface IProps {
  data?: any;
}
const DeviceCompatibilityPopup: FC<IProps> = () => {
  const { t }: Translation = useTranslation();

  const deviceCompatibilityStatus = checkDeviceCompatibility().compatible;

  setBodyOverflow('unset');

  const _closeDialog = useConnectedAction(closeDialog);

  const removeDialog = (): void => {
    setBodyOverflow('set');
    _closeDialog({ dialogType: EnumDialogsKeys.DEVICE_COMPATIBILITY_POPUP });
  };

  return (
    <PopUpContainer
      isOpen={deviceCompatibilityStatus}
      handleOK={removeDialog}
      handleClose={removeDialog}
      hasOverlay
      title={t('deviceCompatibilityError')}
    />
  );
};

export default DeviceCompatibilityPopup;
