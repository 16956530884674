import { useCallback, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';

/**
 * Hook to share a single Socket.IO connection across multiple tabs.
 */
const useActivitySocket: any = (user: any) => {
  const workerRef: any = useRef(null);
  const socketRef: any = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const isWorkerSupported = typeof SharedWorker !== 'undefined';

  const emitEvent = useCallback((eventName: any, data: any) => {
    if (workerRef.current) {
      workerRef.current.port.postMessage({
        type: 'EMIT_EVENT',
        id: 'activitySocket',
        event: eventName,
        data,
      });
    } else if (socketRef.current) {
      socketRef.current.emit(eventName, data);
    }
  }, []);

  useEffect(() => {
    if (!process.env.REACT_APP_ACTIVITY_SOCKET || !user?.token) return;

    if (isWorkerSupported) {
      console.log(`[useActivitySocket] Using SharedWorker.`);
      if (!workerRef.current) {
        workerRef.current = new SharedWorker('/socket-shared-worker.js');
        workerRef.current.port.start();

        workerRef.current.port.onmessage = (event: any) => {
          const { type, id, event: socketEvent, data, status } = event.data;
          console.log(`[useActivitySocket] Worker message:`, event.data);

          switch (type) {
            case 'SOCKET_STATUS':
              console.log(`[useActivitySocket] Socket ${id} is ${status}`);
              setIsConnected(status === 'connected');
              break;
            case 'SOCKET_EVENT':
              console.log(`[useActivitySocket] Received event: ${socketEvent}`, data);
              break;
            case 'SOCKET_ERROR':
              console.error(`[useActivitySocket] Socket error:`, data);
              break;
            default:
              console.warn(`[useActivitySocket] Unknown message type: ${type}`);
          }
        };
      }

      workerRef.current.port.postMessage({
        type: 'INIT_SOCKET',
        id: 'activitySocket',
        url: process.env.REACT_APP_ACTIVITY_SOCKET,
        options: {
          query: { token: user.token },
          transports: ['websocket'],
        },
      });
    } else {
      console.log(`[useActivitySocket] Using direct socket connection.`);
      if (!socketRef.current) {
        socketRef.current = io(`${process.env.REACT_APP_ACTIVITY_SOCKET}?token=${user.token}`, {
          transports: ['websocket'],
        });

        socketRef.current.on('connect', () => {
          console.log('Socket connected');
          setIsConnected(true);
        });

        socketRef.current.on('disconnect', () => {
          console.log('Socket disconnected');
          setIsConnected(false);
        });
      }
    }

    // return () => {
    //   if (workerRef.current) {
    //     workerRef.current.port.postMessage({ type: 'DISCONNECT_SOCKET', id: 'activitySocket' });
    //   } else if (socketRef.current) {
    //     socketRef.current.disconnect();
    //     console.log('Socket disconnected on cleanup');
    //   }
    // };
  }, [user?.token]);

  return { emitEvent, isConnected };
};

export default useActivitySocket;
