import 'core-js/features/array/flat';
import 'core-js/features/array/flat-map';
import React, { FC, useEffect } from 'react';
import { isIOS } from 'react-device-detect';
import ReactGA from 'react-ga4';
import 'react-toastify/dist/ReactToastify.css';
import ErrorBoundaryComponent from './hoc/ErrorBoundary';
import { I18nProvider } from './hoc/I18Provider';
import { Main } from './pages/Main';
import './styles/index.scss';

if (process.env.REACT_APP_MODE !== 'DEV' && process.env.REACT_APP_SMARTICO_ENABLE === 'true') {
  const script = document.createElement('script');
  script.src = 'https://libs.smartico.ai/smartico.js';
  script.onload = function () {
    // Initialize Smartico once the script is loaded
    (window as any)._smartico.init('93bab8d4-db19-4954-9f9e-094f9e5c5e01-3', { brand_key: 'ad714fa7' });
  };
  document.head.appendChild(script);
}

(function initGoogleAnalytics() {
  const hasGoogleAnalytics = process.env?.REACT_APP_GOOGLE_ANALYTICS;
  if (hasGoogleAnalytics) {
    ReactGA.initialize(hasGoogleAnalytics);
  }
})();

const App: FC = () => {
  const isEnableMaintenancePage = process.env.REACT_APP_MAINTENANCE_PAGE === 'true';

  useEffect(() => {
    if (isEnableMaintenancePage) {
      window.location.href = 'https://www.papipromopages.com/underconstruction';
    }
  }, [isEnableMaintenancePage]);

  if (isIOS) {
    // Create a meta tag element
    const viewportMetaTag = document.createElement('meta');

    // Set attributes for the meta tag
    viewportMetaTag.setAttribute('name', 'viewport');
    viewportMetaTag.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');

    // Append the meta tag to the head element of the document
    document.head.appendChild(viewportMetaTag);
  }
  return (
    <I18nProvider>
      <ErrorBoundaryComponent>
        <Main />
      </ErrorBoundaryComponent>
    </I18nProvider>
  );
};
export default App;
