import cn from 'classnames';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components/common/Button';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { resetSectionLoading, setGameBoxWidgetOpen, setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { RootState } from 'src/types/store-types';
import { setBodyOverflow } from '../../../helpers/utils';
import { openMyAccountModal } from '../../../store/account-modal/actions';
import './styles.scss';

type IPromotionProps = {
  needLogin: false;
  type: string;
  name: string;
  imageName: string | null;
  text?: string;
  itemSize?: string; // large | normal | small
  styles: Record<string, string>;
};

type Props = {
  settings: IPromotionProps[];
};

const SideBarPromotion: FC<Props> = ({ settings }) => {
  const navigate = useNavigate();

  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _setGameBoxWidgetOpen = useConnectedAction(setGameBoxWidgetOpen);
  const _resetSectionLoading = useConnectedAction(resetSectionLoading);

  const { sideBarMenuIsOpen, selectedCategory } = useSelector((state: RootState) => state.app);

  const selectCategory = (menuItem: string): void => {
    _resetSectionLoading();
    navigate(`/category/${menuItem}`);
    _setSelectedCategory(menuItem);
  };

  const openDepositModal = (_user: string | null): void => {
    if (_user) {
      _openAccountModal({ section: 'referAFriend', subSection: 'referAFriend' });
    } else {
      setBodyOverflow('unset');
      _openAuthModal('login');
    }
  };

  const changeSelectedMenuItem = (menuItem: any): void => {
    const _user = localStorage.getItem('user');

    if (menuItem?.needLogIn && !_user) {
      _openAuthModal('login');
    } else {
      switch (menuItem.name) {
        case 'smartico':
          (window as any)._smartico?.dp('dp:gf');
          break;
        case 'gamebox':
          _setGameBoxWidgetOpen();
          break;
        case 'promotions':
          _resetSectionLoading();
          _setSelectedCategory(menuItem.name);
          navigate('/promotions');
          break;
        case 'ref-friend':
          openDepositModal(_user);
          break;
        default:
          selectCategory(menuItem.name);
          break;
      }
    }
  };

  const renderPromotionItems = useCallback(
    (item: IPromotionProps): JSX.Element => {
      let _imageName = item?.imageName ? item?.imageName : item.name;

      !sideBarMenuIsOpen && (_imageName += '-closed');
      //  selectedCategory === item.name && (_imageName += '-selected');
      return (
        <Button
          className={cn('promotion-item', item?.itemSize, { ['promotion-item__order']: !sideBarMenuIsOpen })}
          key={item.name}
          type="button"
          onClick={() => changeSelectedMenuItem(item)}
        >
          <img
            style={item?.styles}
            className="promotion-item__image"
            src={`/images/promotion/${_imageName}.svg`}
            alt="Promo"
          />
        </Button>
      );
    },
    [selectedCategory, sideBarMenuIsOpen]
  );

  return (
    <div className="sidebar_promotion_section">
      {settings?.map((promo: IPromotionProps): any => renderPromotionItems(promo))}
    </div>
  );
};

export default SideBarPromotion;
