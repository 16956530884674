import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { RootState } from 'src/types/store-types';

export const RaceIframe = (): JSX.Element => {
  const isRaceLoggedIn = useRef(false);
  const [fullScreen] = useState(false);
  const { locale } = useI18n();
  const navigate = useNavigate();
  const { user } = useSelector((state: RootState) => state.user);
  const frameRef = useRef<HTMLIFrameElement | null>(null);
  const interval = useRef<any>(null);
  const [frameUrl, setFrameUrl] = useState('');
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const [isFrameLoaded, setIsFrameLoaded] = useState(false);
  const sessionRecorder = useSessionRecorder();

  const iniRaceFrame = (): void => {
    // @ts-expect-error gdlib global types missing
    const url = window.__gdlib.race_url;
    if (url) {
      setFrameUrl(url);
      registerListeners();
      return;
    }

    interval.current = setInterval(() => {
      // @ts-expect-error gdlib global types missing
      const url = window.__gdlib.race_url;
      if (url) {
        setFrameUrl(url);
        registerListeners();
        clearInterval(interval.current);
      }
    }, 1000);
  };

  const authorizeUser = useCallback((): void => {
    if (!isFrameLoaded) return;

    if (isRaceLoggedIn.current && !user) {
      frameRef.current?.contentWindow?.postMessage(
        {
          type: 'LOGOUT',
          user,
        },
        '*'
      );
      isRaceLoggedIn.current = false;
      return;
    }

    if (!isRaceLoggedIn.current && user) {
      frameRef.current?.contentWindow?.postMessage(
        {
          type: 'LOGIN',
          user: { ...user, userId: user.id },
        },
        '*'
      );
      isRaceLoggedIn.current = true;
    }
  }, [isFrameLoaded, user]);

  const handleLangChange = useCallback((): void => {
    if (!isFrameLoaded) return;

    frameRef.current?.contentWindow?.postMessage(
      {
        type: 'CHANGE_LANGUAGE',
        language: locale,
      },
      '*'
    );
  }, [isFrameLoaded, locale]);

  const registerListeners = (): void => {
    window.addEventListener('message', (event) => {
      switch (event.data.type) {
        case 'MOUNTED':
          setIsFrameLoaded(true);
          break;
        case 'CHANGE_CATEGORY':
          _setSelectedCategory(event.data.category);
          navigate(`/category/${event.data.category}`);
          break;
        case 'OPEN_LOGIN':
          setBodyOverflow('unset');
          _openAuthModal('login');
          break;
      }
    });
  };

  useEffect(() => {
    iniRaceFrame();

    return () => interval.current && clearInterval(interval.current);
  }, []);

  useEffect(() => {
    authorizeUser();
  }, [authorizeUser]);

  useEffect(() => {
    handleLangChange();
  }, [handleLangChange]);

  useEffect(() => {
    sessionRecorder?.trackEvent('Race page view');
  }, []);

  return (
    <div className="race fullWidth">
      <iframe
        allow="fullscreen"
        ref={frameRef}
        className={cn('frame', { ['fullScreen']: fullScreen })}
        id="raceIframe"
        src={frameUrl}
      />
    </div>
  );
};
