import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys, EnumStatusesOfKyc } from 'src/store/dialog/types';
import { setGameBoxMissions, setTournamentGame } from 'src/store/integrations/actions';
import { setBalance } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';

export const GosIframe = (): JSX.Element => {
  const [fullScreen, setFullScreen] = useState(true);
  const frameRef = useRef<HTMLIFrameElement | null>(null);
  const interval = useRef<any>(null);
  const [frameUrl, setFrameUrl] = useState('');
  const [isFrameLoaded, setIsFrameLoaded] = useState(false);
  const sessionRecorder = useSessionRecorder();
  const { section } = useSelector((state: RootState) => state.accountModal);

  const { locale } = useI18n();
  const navigate = useNavigate();

  const _requestGameBoxMission = useConnectedAction(setGameBoxMissions.req);
  const _resetExternalTournamentGame = useConnectedAction(setTournamentGame);
  const _requestBalance = useConnectedAction(setBalance.req);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _openDialog = useConnectedAction(openDialog);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);

  const { user, kycStatus } = useSelector((state: RootState) => state.user);
  const { selectedTournamentGame, showTournamentPage } = useSelector((state: RootState) => state.integrations);
  const initGosFrame = useCallback(() => {
    const url = window.__gdlib?.casinoTournament_url;
    if (url) {
      setFrameUrl(url);
      registerListeners();
      return;
    }

    interval.current = setInterval(() => {
      const url = window.__gdlib?.casinoTournament_url;
      if (url) {
        setFrameUrl(url);
        registerListeners();
        clearInterval(interval.current);
      }
    }, 1000);
  }, []);

  const authorizeUser = useCallback(() => {
    if (!isFrameLoaded) return;
    user
      ? frameRef.current?.contentWindow?.postMessage(
          {
            type: 'LOGIN',
            user: { ...user, userId: user.id },
          },
          '*'
        )
      : frameRef.current?.contentWindow?.postMessage(
          {
            type: 'LOGOUT',
          },
          '*'
        );
  }, [user, isFrameLoaded]);

  const handleLangChange = useCallback(() => {
    if (!isFrameLoaded) return;

    frameRef.current?.contentWindow?.postMessage(
      {
        type: 'languageChange',
        language: locale,
      },
      '*'
    );
  }, [locale, isFrameLoaded]);

  const handleGameOpen = useCallback(() => {
    if (!isFrameLoaded) return;

    frameRef.current?.contentWindow?.postMessage(
      {
        type: 'GAME_OPEN',
        selectedTournamentGame,
      },
      '*'
    );
  }, [selectedTournamentGame, isFrameLoaded]);

  const handleShowTournamentPage = useCallback(() => {
    if (!isFrameLoaded) return;

    frameRef.current?.contentWindow?.postMessage(
      {
        type: 'DIALOG_OPEN',
        dialogName: 'howToPlay',
      },
      '*'
    );
  }, [showTournamentPage, isFrameLoaded]);
  const registerListeners = (): void => {
    window.addEventListener('message', (event) => {
      switch (event.data.type) {
        case 'MOUNTED':
          setIsFrameLoaded(true);
          break;
        case 'spinAndGoStarted':
          _openDialog({
            dialogType: EnumDialogsKeys.SPIN_AND_GO_STARTED,
            dialogProps: { data: { tournamentId: event.data.tournamentId } },
          });
          break;
        case 'navigate/category':
          _setSelectedCategory('silvaSeries');
          navigate(`/category/silvaSeries`);
          break;
        case 'changeCategory':
          _setSelectedCategory(event.data.category);
          navigate(`/category/${event.data.category}`);
          break;
        case 'resumeTournament':
          _openDialog({
            dialogType: EnumDialogsKeys.RESUME_TOURNAMENT,
            dialogProps: { data: event.data.tournament },
          });
          break;
        case 'successfullRegistration':
          localStorage.setItem('resumeGameId', event.data.id);
          break;
        case 'successfullUnregistration':
          localStorage.removeItem('resumeGameId');
          break;
        case 'openLoginForm':
          setBodyOverflow('unset');
          _openAuthModal('login');
          break;
        case 'balanceUpdate':
          _requestBalance();
          break;
        case 'scrollLock':
          setBodyOverflow(event.data.value ? 'set' : 'unset');
          break;
        case 'gameStateChanged':
          if (!event.data.fullScreen) {
            setTimeout(() => {
              if (user) {
                _requestGameBoxMission();
                _resetExternalTournamentGame(null);
              }
            }, 500);
          }
          setFullScreen(event.data.fullScreen);
          break;
      }
    });
  };

  useEffect(() => {
    initGosFrame();

    return () => interval.current && clearInterval(interval.current);
  }, []);

  useEffect(() => {
    authorizeUser();
  }, [authorizeUser]);

  useEffect(() => {
    handleLangChange();
  }, [handleLangChange]);

  useEffect(() => {
    handleGameOpen();
  }, [handleGameOpen]);

  useEffect(() => {
    handleShowTournamentPage();
  }, [handleShowTournamentPage]);

  useEffect(() => {
    sessionRecorder?.trackEvent('Gos page view');
  }, []);

  useEffect(() => {
    let interval: any;

    if (
      !section &&
      user &&
      process.env.REACT_APP_KYC_ENABLE === 'true' &&
      (kycStatus === EnumStatusesOfKyc.PENDING ||
        kycStatus === EnumStatusesOfKyc.EXPIRED ||
        kycStatus === EnumStatusesOfKyc.REJECTED ||
        kycStatus === undefined)
    ) {
      interval = setInterval(() => {
        _openDialog({
          dialogType: EnumDialogsKeys.KYC_POPUP,
          dialogProps: { data: {} },
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [kycStatus, user, section]);

  return (
    <div className="casinoTournament fullWidth">
      <iframe
        allow="fullscreen"
        ref={frameRef}
        className={cn('frame', { ['fullScreen']: fullScreen })}
        id="tournament"
        src={frameUrl}
      />
    </div>
  );
};
