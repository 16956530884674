import cn from 'classnames';
import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import CountdownTimer from 'src/components/common/CountdownTimer';
import { formatCurrency } from 'src/helpers/utils';
import { IActiveRafflesType } from 'src/types/res-dto/raffleDraw';
import './styles.scss';

type IProps = {
  data: IActiveRafflesType;
  classnames?: string;
};

const RaffleDrawInfo: FC<IProps> = ({ data, classnames }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('raffle_draw_info_container', classnames)}>
      <img
        src={`/images/raffleDraw/${isMobile ? 'raffle_draw_background_mobile' : 'raffle_draw_background'}.png`}
        alt="raffle_draw"
        className="raffle_draw_img"
      />
      <div className="raffle_draw_info_content">
        <h3 className="raffle_draw_name">{data.name}</h3>
        <span className="raffle_draw_prize">{formatCurrency(data.total_prize, data.currency)}</span>
        <div className="countdown_wrapper">
          <span className="countdown_wrapper_text">{t('endsIn')}</span>
          <CountdownTimer size="lg" targetDate={data.expire_time} view="names" />
        </div>
      </div>
    </div>
  );
};

export default RaffleDrawInfo;
