import { ChangeEvent, FC, MouseEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import { openMyAccountModal } from '../../../store/account-modal/actions';
import { Checkbox } from '../Checkbox';

type IProps = {
  onChange: (e: any) => void;
  id: string;
  value: boolean;
};
const BlockOfPrivacyPolicy: FC<IProps> = ({ onChange, id, value }) => {
  // Hooks
  const { t }: Translation = useTranslation();

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  // YUP Validation
  const validationSchema = Yup.object().shape({
    generalBlockOfTermsAndConditions: Yup.boolean().oneOf([true], t('requiredField')).required(t('requiredField')),
  });

  const openInfoAndRulesModal = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    _openAccountModal({ section: 'info_and_rules', subSection: 'Terms and Conditions', fullHeight: true });
  };

  const openPrivacyPolicyModal = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    _openAccountModal({ section: 'info_and_rules', subSection: 'Privacy Policy', fullHeight: true });
  };

  const openTerrorismRegistry = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    window.open('https://repet.jus.gob.ar/', '_blank');
  };

  const openPoliticallyExposedPerson = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    // Directly reference the PDF file in the public folder
    const pdfUrl = '/pdf/pep.pdf';

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');

    // Trigger download
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'politically_exposed_person.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const termsAndConditionsTextForEasyBet = useMemo(() => {
    return (
      <>
        <span>
          {t('easyBetTerms.part1')}
          <span className="terms_and_conditions_checkbox__link" onClick={(e) => openPoliticallyExposedPerson(e)}>
            {t('easyBetTerms.politicallyExposedPerson')}
          </span>
        </span>
        <span>
          {t('easyBetTerms.part2')}
          <span className="terms_and_conditions_checkbox__link" onClick={(e) => openInfoAndRulesModal(e)}>
            {t('easyBetTerms.prohibitions')}
          </span>
        </span>
        <span>
          {t('easyBetTerms.part3')}
          <span className="terms_and_conditions_checkbox__link" onClick={(e) => openTerrorismRegistry(e)}>
            {t('easyBetTerms.terrorismRegistry')}
          </span>
        </span>
        <span>
          {t('easyBetTerms.part4')}
          <span className="terms_and_conditions_checkbox__link" onClick={(e) => openInfoAndRulesModal(e)}>
            {t('easyBetTerms.termsConditionsPrivacy')}
          </span>
        </span>
        <span>
          {t('la')}
          <span className="terms_and_conditions_checkbox__link" onClick={(e) => openPrivacyPolicyModal(e)}>
            {t('easyBetTerms.privacyPolicy')}
          </span>
          <span>{t('easyBetTerms.part5')}</span>
        </span>
      </>
    );
  }, []);

  const termsAndConditionsCheckboxText = useMemo(() => {
    return (
      <>
        {t('termsConditionsLabel')}
        <span className="terms_and_conditions_checkbox__link" onClick={(e) => openInfoAndRulesModal(e)}>
          {t('generalTermsConditions')}
        </span>
        <span className="terms_and_conditions_checkbox__link" onClick={(e) => openPrivacyPolicyModal(e)}>
          {t('termsConditionsPrivacy')}
        </span>
        <p>{t('authorizedMonitoring')}</p>
      </>
    );
  }, []);

  const handleValidate = async (value: boolean): Promise<void> => {
    try {
      await validationSchema.validate({ generalBlockOfTermsAndConditions: value }, { abortEarly: false });
      resetSignUpError('generalBlockOfTermsAndConditions');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, generalBlockOfTermsAndConditions: error.errors[0] });
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange({ target: { name: 'generalBlockOfTermsAndConditions', value: e.target.checked } });
    handleValidate(e.target.checked).catch((e) => console.log(e));
    if (signUpErrors.generalBlockOfTermsAndConditions) {
      resetSignUpError('generalBlockOfTermsAndConditions');
    }
  };

  return (
    <Checkbox
      error={signUpErrors.generalBlockOfTermsAndConditions}
      onChange={handleOnChange}
      label={
        process.env.REACT_APP_WEBSITENAME === 'EasyBet'
          ? termsAndConditionsTextForEasyBet
          : termsAndConditionsCheckboxText
      }
      id={id}
      name="generalBlockOfTermsAndConditions"
      value={Boolean(value)}
    />
  );
};
export default BlockOfPrivacyPolicy;
