import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useI18n } from 'src/hooks//use-i18n-translation';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { RootState } from 'src/types/store-types';

export const FunMissionFrame = (): JSX.Element => {
  const [fullScreen] = useState(false);
  const { locale } = useI18n();
  const { user } = useSelector((state: RootState) => state.user);
  const frameRef = useRef<HTMLIFrameElement | null>(null);
  const interval = useRef<any>(null);
  const [frameUrl, setFrameUrl] = useState('');
  const [isFrameLoaded, setIsFrameLoaded] = useState(false);
  const sessionRecorder = useSessionRecorder();

  const registerListeners = (): void => {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'MOUNTED') {
        setIsFrameLoaded(true);
      }
    });
  };

  const initFunMissionFrame = useCallback(() => {
    // @ts-expect-error gdlib global types missing
    const url = window.__gdlib['fun-mission_url'];
    if (url) {
      setFrameUrl(url);
      registerListeners();
      return;
    }

    interval.current = setInterval(() => {
      // @ts-expect-error gdlib global types missing
      const url = window.__gdlib['fun-mission_url'];
      if (url) {
        setFrameUrl(url);
        registerListeners();
        clearInterval(interval.current);
      }
    }, 1000);
  }, []);

  const authorizeUser = useCallback(() => {
    if (!isFrameLoaded) return;
    user
      ? frameRef.current?.contentWindow?.postMessage(
          {
            type: 'LOGIN',
            user,
          },
          '*'
        )
      : frameRef.current?.contentWindow?.postMessage(
          {
            type: 'LOGOUT',
            user,
          },
          '*'
        );
  }, [user, isFrameLoaded]);

  const handleLangChange = useCallback((): void => {
    if (!isFrameLoaded) return;

    frameRef.current?.contentWindow?.postMessage(
      {
        type: 'CHANGE_LANGUAGE',
        language: locale,
      },
      '*'
    );
  }, [isFrameLoaded, locale]);

  useEffect(() => {
    initFunMissionFrame();

    return () => interval.current && clearInterval(interval.current);
  }, []);
  useEffect(() => {
    authorizeUser();
  }, [authorizeUser]);
  useEffect(() => {
    handleLangChange();
  }, [handleLangChange]);
  useEffect(() => {
    sessionRecorder?.trackEvent('Fun mission page view');
  }, []);
  return (
    <div className="fun-mission fullWidth">
      <iframe
        allow="fullscreen"
        ref={frameRef}
        className={cn('frame', { ['fullScreen']: fullScreen })}
        id="funMissionIframe"
        src={frameUrl}
      />
    </div>
  );
};
