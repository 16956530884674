import { createReducer } from 'deox';
import produce from 'immer';
import { isTokenExpired } from 'src/helpers/utils';
import {
  resetForceLogout,
  setBalance,
  setCPFValue,
  setCPF_User,
  setDniResult,
  setError,
  setForceLogout,
  setKycStatus,
  setLoading,
  setLogout,
  setPassword,
  setPasswordError,
  setPhoneCode,
  setReferralLink,
  signIn,
  signInVerificationData,
  toggleActivateBtn,
  toggleActivateNextStep,
  userUpdateLoading,
} from './actions';
import { userState } from './types';

const defaultUser = (): any => {
  const userData = localStorage.getItem('user');
  if (!userData) return null;

  let _user;
  try {
    _user = JSON.parse(userData);

    if (typeof _user !== 'object' || _user === null) {
      console.error('Invalid user data format:', userData);
      return null;
    }
  } catch (e) {
    console.error('Error parsing user data from localStorage:', e, 'Data:', userData);
    return null;
  }

  return isTokenExpired() ? null : _user;
};

const defaultKycStatus = (): any => {
  const userData = localStorage.getItem('user');
  if (!userData) return undefined;

  let _user;
  try {
    _user = JSON.parse(userData);
  } catch (e) {
    console.error('Invalid JSON in localStorage:', e);
    return undefined;
  }

  if (_user && typeof _user === 'object' && _user?.kyc && Object.keys(_user?.kyc)?.length) {
    return _user?.kyc?.verificationStatus;
  }

  return undefined;
};

export const userInitialState: userState = {
  user: defaultUser(),
  userUpdateLoading: false,
  additionalInfo: null,
  loading: false,
  authLoading: false,
  balanceIsLoading: false,
  wallets: localStorage.getItem('wallets') ? JSON.parse(localStorage.getItem('wallets') || '') : null,
  gamePreviewProducts: [],
  activateButton: false,
  activateNextStep: false,
  errors: {},
  dniResult: {},
  password: '',
  repeatPassword: '',
  phoneCode: '',
  kycStatus: defaultKycStatus(),
  passwordError: '',
  forceLogout: null, // user
  cpfValue: '',
  signinVerificationData: {
    username: '',
    isOpen: false,
  },
  referralLinks: localStorage.getItem('referralLinks') ? JSON.parse(localStorage.getItem('referralLinks') || '') : null,
};

export const userReducer = createReducer(userInitialState, (handle) => [
  handle(setLogout, (state, {}: any) =>
    produce(state, (draft) => {
      const isWorkerSupported = typeof SharedWorker !== 'undefined';
      if (isWorkerSupported) {
        const sharedWorker = new SharedWorker('/socket-shared-worker.js');
        sharedWorker.port.postMessage({ type: 'DISCONNECT_ALL_SOCKETS' });
      }

      draft.user = null;
      localStorage.removeItem('user');
      draft.referralLinks = null;
      localStorage.removeItem('referralLinks');
      sessionStorage.removeItem('POKER_URL');
    })
  ),
  handle(setCPFValue, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.cpfValue = payload;
    })
  ),
  handle(signInVerificationData, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.signinVerificationData = payload;
    })
  ),
  handle(signIn.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.user = payload;
    })
  ),
  handle(signIn.load, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.authLoading = payload;
    })
  ),
  handle(setError, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.errors = payload;
    })
  ),
  handle(setDniResult, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.dniResult = payload;
    })
  ),
  handle(setLoading, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.loading = payload;
    })
  ),
  handle(setCPF_User, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.additionalInfo = payload;
    })
  ),
  handle(setBalance.req, (state, {}: any) =>
    produce(state, (draft) => {
      draft.balanceIsLoading = true;
    })
  ),
  handle(setBalance.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.wallets = payload;
      draft.gamePreviewProducts = payload.find((wal: any) => wal?.walletType === 'bonus')?.products || [];
      const storedWallets = localStorage.getItem('wallets');
      if (!!storedWallets) {
        localStorage.setItem('wallets', JSON.stringify(payload));
      }
      draft.balanceIsLoading = false;
    })
  ),
  handle(toggleActivateBtn, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.activateButton = payload;
    })
  ),
  handle(toggleActivateNextStep, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.activateNextStep = payload;
    })
  ),
  handle(setPassword, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft[payload.key] = payload.value;
    })
  ),
  handle(setPhoneCode, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.phoneCode = payload;
    })
  ),
  handle(setKycStatus, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.kycStatus = payload;
    })
  ),
  handle(setForceLogout, (state, { payload }: any) =>
    produce(state, (draft) => {
      localStorage.removeItem('user');
      draft.forceLogout = { message: payload };
    })
  ),
  handle(resetForceLogout, (state) =>
    produce(state, (draft) => {
      draft.forceLogout = null;
    })
  ),
  handle(setPasswordError, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.passwordError = payload;
    })
  ),
  handle(setReferralLink, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.referralLinks = payload;
      localStorage.setItem('referralLinks', JSON.stringify(payload));
    })
  ),
  handle(userUpdateLoading, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.userUpdateLoading = payload;
    })
  ),
]);
