import { APP_API } from 'src/utils/constants';
import HttpClient from './http';

const getActiveRaffles = (): Promise<any> => {
  return HttpClient.get({
    path: `${APP_API}/getActiveRaffles`,
    baseURL: process.env.REACT_APP_GAME_BOX as string,
  });
};

const getActiveRafflesTickets = (): Promise<any> => {
  return HttpClient.get({
    path: `${APP_API}/getActiveRafflesTickets`,
    baseURL: process.env.REACT_APP_GAME_BOX as string,
  });
};

export { getActiveRaffles, getActiveRafflesTickets };
