import cn from 'classnames';
import { ChangeEvent, FC, ReactElement } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { Typography } from '../Typography';
import styles from './styles.module.scss';

type Props = {
  defaultChecked?: boolean;
  containerClassName?: string;
  className?: string;
  disabled?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
  size?: string;
  name: string;
  id: string;
  label?: string | ReactElement;
  error?: string;
};

export const Checkbox: FC<Props> = ({
  defaultChecked,
  containerClassName,
  onChange,
  disabled,
  value,
  size,
  name,
  id,
  label,
  className,
  error,
}) => {
  return (
    <>
      <div className={cn(styles.checkbox_container, containerClassName)}>
        <div
          className={cn(
            styles.checkbox,
            {
              [styles[`checkbox_size_${size}`]]: size,
              [styles.checkbox_disabled]: disabled,
            },
            styles.checkbox_wrapper
          )}
        >
          <input
            defaultChecked={defaultChecked}
            onChange={onChange}
            disabled={disabled}
            checked={value}
            type="checkbox"
            name={name}
            id={id}
          />
          <label className={styles.checkbox__label} htmlFor={id}>
            <span className={styles.checkbox__icon}>
              <IoMdCheckmark />
            </span>
          </label>
        </div>
        {label && (
          <Typography variant="body3" fontWeight="normal" className={className}>
            <label htmlFor={id}>{label}</label>
          </Typography>
        )}
      </div>
      {error && (
        <Typography color={'error'} variant="body5" className={styles.checkbox_error}>
          {error}
        </Typography>
      )}
    </>
  );
};
