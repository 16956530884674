import { useEffect } from 'react';

export const useGA = (action: string, event_category: string, event_label: string): void => {
  useEffect(() => {
    // Google Analytics event tracking
    if (typeof window !== 'undefined' && (window as any).gtag) {
      (window as any).gtag('event', action, {
        event_category,
        event_label: event_label,
      });
    }
  }, []);
};
