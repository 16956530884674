import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { setBodyOverflow } from 'src/helpers/utils';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { RootState } from 'src/types/store-types';
import './styles.scss';

export const FunMissionView = (): JSX.Element => {
  const FunMissionSDK = useRef<FunMissionSDK>();
  const { locale } = useI18n();
  const { user } = useSelector((state: RootState) => state.user);
  const sessionRecorder = useSessionRecorder();
  const [fullScreen] = useState(false);

  const registerListeners = (): void => {
    FunMissionSDK.current?.addEventListener('funBonusSetGameUrl', (data: any) => {
      window.__gdlib?.callRegisteredCB('funBonusSetGameUrl', data);
    });
  };

  const authorizeUser = useCallback((): void => {
    user ? FunMissionSDK.current?.actions?.login(user) : FunMissionSDK.current?.actions?.logout();
  }, [user]);

  const mountFunMissionApp = (): void => {
    setBodyOverflow('set');

    if (!window.__gdlib?.__FunMissionSDK) return;

    if (window.__gdlib.__FunMissionSDK?.init) {
      window.__gdlib.__FunMissionSDK.init().then(() => {
        FunMissionSDK.current = window.window.__gdlib?.__FunMissionSDK.instance;
        FunMissionSDK.current?.mount({
          onReady: () => {
            registerListeners();
            authorizeUser();
          },
        });
      });
      return;
    }

    const __FunMissionSDKProxy = new Proxy(window.__gdlib.__FunMissionSDK, {
      set(target: any, prop, newValue) {
        target[prop] = newValue;
        if (prop === 'init' && typeof newValue === 'function') {
          newValue().then(() => {
            FunMissionSDK.current = target.instance;
            FunMissionSDK.current?.mount({
              onReady: () => {
                registerListeners();
                authorizeUser();
              },
            });
          });
        }
        return true;
      },
    });

    window.__gdlib.__FunMissionSDK = __FunMissionSDKProxy;
  };

  useEffect(() => {
    if (FunMissionSDK.current) {
      FunMissionSDK.current.actions.handleLanguageChange(locale);
    }
  }, [locale]);

  useEffect(() => {
    if (FunMissionSDK.current) {
      authorizeUser();
    }
  }, [authorizeUser]);

  useEffect(() => {
    mountFunMissionApp();

    return () => {
      FunMissionSDK.current?.unMount();
    };
  }, []);

  useEffect(() => {
    sessionRecorder?.trackEvent('Fun mission page view');
  }, []);

  return (
    <div className={cn('page_container', 'funMissionView_Wrapper', { ['funMissionView_Wrapper--mobile']: isMobile })}>
      <div className={cn('funMissionView_Container', { ['fullScreen']: fullScreen })} id="__FUN_MISSION__" />
    </div>
  );
};
export default FunMissionView;
