import cn from 'classnames';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getModuleFrames } from 'src/helpers/getModuleFrames';
import { RootState } from 'src/types/store-types';
import { IFRAMES } from 'src/utils/constants';
import './styles.scss';

const Scrollbar: FC<React.ComponentPropsWithoutRef<'div'>> = ({ children, className, ...props }) => {
  const { selectedCategory, sideBarMenuIsOpen } = useSelector((state: RootState) => state.app);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);

  const isNotIframeSelected = ![...IFRAMES, ...getModuleFrames()].includes(selectedCategory);

  return (
    <div
      className={cn('custom-scrollbars__main ', {
        ['custom-scrollbars__container']: isNotIframeSelected,
        ['sidebar_is_open']: sideBarMenuIsOpen,
        ['sidebar']: generalConfigs?.hasSideBar,
      })}
    >
      <div
        className={cn('custom-scrollbars__content', {
          ['custom-scrollbars__content']: isNotIframeSelected,
        })}
        id="scroll_container"
        {...props}
      >
        {children}
      </div>
    </div>
  );
};

export default Scrollbar;
