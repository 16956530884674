import * as Yup from 'yup';

const forgotPasswordValidationScheme = (
  t: (arg: string) => string,
  isCpfExists: boolean
): Yup.ObjectSchema<Record<string, any>> => {
  return Yup.object().shape({
    username: Yup.string().required(t('requiredField')),
    email: Yup.string().email(t('pleaseEnterValidEmailAdress')).required(t('requiredField')),
    cpf: Yup.string().when([], {
      is: () => isCpfExists,
      then: (schema) => schema.matches(/^\d{11}$/, t('invalid_CPF_length')).required(t('requiredField')),
    }),
  });
};

const loginFormValidation = (t: (arg: string) => string): Yup.ObjectSchema<Record<string, any>> => {
  return Yup.object().shape({
    username: Yup.string().required(t('requiredField')),
    password: Yup.string().required(t('requiredField')),
  });
};
const loginWithOptionFormValidation = (t: (arg: string) => string): Yup.ObjectSchema<Record<string, any>> => {
  return Yup.object().shape({
    email: Yup.string()
      .email(t('pleaseEnterValidEmailAdress'))
      .test('emailOrCpfRequired', t('requiredField'), function (value) {
        const { cpf } = this.parent;
        return !value && !cpf ? this.createError({ path: 'email', message: t('requiredField') }) : true;
      }),
    cpf: Yup.number().test('emailOrCpfRequired', t('requiredField'), function (value) {
      const { email } = this.parent;
      return !value && !email ? this.createError({ path: 'cpf', message: t('requiredField') }) : true;
    }),
    password: Yup.string().required(t('requiredField')),
  });
};

const payloadIsValid = (
  payload: any,
  requiredFields: any[],
  setLocaleErrorsState: (err: any) => any,
  t: any
  // eslint-disable-next-line sonarjs/cognitive-complexity
): boolean => {
  let isValid = true;

  const emptyFields = requiredFields.reduce((acc, cur: string) => {
    if (cur === 'dni') {
      if (!payload.dni) {
        acc[cur] = 'requiredField';
      }
      if (!payload.idMainIssue) {
        acc[cur] = 'requiredField';
      }
    } else {
      if (!payload[cur]) {
        acc[cur] = 'requiredField';
      }
    }

    return acc;
  }, {});

  if (Object.keys(emptyFields).length > 0) {
    isValid = false;
  } else {
    setLocaleErrorsState(emptyFields);
  }

  if (payload.password && payload.password2 && payload.password !== payload.password2) {
    isValid = false;

    setLocaleErrorsState((prevState: { [key: string]: string } | null) => {
      if (prevState) {
        return {
          ...prevState,
          password2: t('password_must_be_a_match'),
        };
      }
      return {
        password2: t('password_must_be_a_match'),
      };
    });
  }

  return isValid;
};

export { forgotPasswordValidationScheme, loginFormValidation, loginWithOptionFormValidation, payloadIsValid };
