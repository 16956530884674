/* eslint-disable sonarjs/cognitive-complexity */
import cn from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from 'src/components/common/Button';
import { Loader } from 'src/components/common/Loader';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { Typography } from 'src/components/common/Typography';
import { icons } from 'src/configs/icons';
import { configureRedirectWindow } from 'src/helpers/transformers';
import { getTransactionData, isPaymentDataValid, reachTextParser } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useQuery } from 'src/hooks/useQuery';
import { getIframeURLRequest } from 'src/services/userApi';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys, EnumStatusesOfKyc } from 'src/store/dialog/types';
import { setTransactions, setTrasactionNotification } from 'src/store/transactions/actions';
import { RootState } from 'src/types/store-types';
import GroupedPayments from './components/GroupedPayments';
import GroupedPayment from './components/GroupedPayments/GroupedPayment';
import PaymentInformation from './components/PaymentInformation';
import PaymentNotification from './components/PaymentNotification';
import PaymentTabs from './components/PaymentTabs';
import WordPressConversionPixel from './components/PixelConversation';
import { Constructor } from './constructor';
import './styles.scss';

type Props = {
  paymentMethodsData: PaymentMethod[] | null;
  groupedPayments: { [key: string]: PaymentMethod[] } | null;
  method: string;
  isLoading: boolean;
  setAdditionalFields: (data: any) => void;
  additionalFields: null;
  paymentFields: Record<string, string>;
  setPaymentFields: (data: any) => void;
  showTransactionResult: boolean;
  setShowTransactionResult: (st: boolean) => void;
  setIsLoading: (st: boolean) => void;
};
const PaymentView: FC<Props> = ({
  groupedPayments,
  paymentMethodsData,
  method,
  isLoading,
  additionalFields,
  setAdditionalFields,
  paymentFields,
  setPaymentFields,
  showTransactionResult,
  setShowTransactionResult,
  setIsLoading,
}) => {
  const { locale } = useI18n();
  const { t }: Translation = useTranslation();
  const queries = useQuery();

  const _setTransactions = useConnectedAction(setTransactions.req);
  const _setTrasactionNotification = useConnectedAction(setTrasactionNotification);

  const { user, wallets, kycStatus } = useSelector((state: RootState) => state.user);
  const { generalConfigs } = useSelector((state: RootState) => state.configs);
  const _openDialog = useConnectedAction(openDialog);

  const { transactionStatus, transactionNotification, transactionLoading } = useSelector(
    (state: RootState) => state.transactions
  );

  const [selectedPaymentID, setSelectedPaymentID] = useState<number>(1);
  const [selectedPayment, setSelectedPayment] = useState<PaymentMethod | null>(null);
  const [paymentError, setPaymentError] = useState<{ [keys: string]: string } | null>(null);
  const [redirectWindow] = useState<Window | null>(null);
  const [selectedGroupOfPayment, setSelectedGroupOfPayment] = useState<string>('');
  const [isDeepDepositValueSeted, setIsDeepDepositValueSeted] = useState<boolean>(false);
  const isWithdrawelInProcess: any = useRef(null);

  const { cookiesAccepted } = useSelector((state: RootState) => state.app);
  const [paymentFrameUrl, setPaymentFrameUrl] = useState<string>('');
  const [purchaseData, setPurchaseData] = useState<any>({
    isDone: false,
    purchaseAmount: 0,
    purchaseCurrency: 'ARS', // MUST REPLACE AND MAKE DINAMIC
  });

  const handleFieldChange = useCallback(
    (fieldValue: string, fieldName: string): void => {
      setPaymentError(null);

      const addedFields = selectedPayment?.fields[fieldName]?.addedFields;
      const isFieldValueInAddedFields = addedFields && addedFields[fieldValue];
      if (isFieldValueInAddedFields) {
        const _additionalFields = Object.keys(addedFields[fieldValue]).reduce((acc: any, key) => {
          acc[key] = null;
          return acc;
        }, {});

        setAdditionalFields({ [fieldName]: addedFields[fieldValue] });
        setPaymentFields({ ...paymentFields, ..._additionalFields, [fieldName]: fieldValue });
        return;
      }

      const isFieldArrayType = selectedPayment?.fields?.[fieldName]?.type === 'array';
      const isFieldValueNotInAddedFields = addedFields && !Object.keys(addedFields).includes(fieldValue);

      if (isFieldArrayType && isFieldValueNotInAddedFields) {
        setAdditionalFields(null);
        const _fields = { ...selectedPayment?.fields };

        Object.keys(_fields).forEach((key) => (_fields[key] = paymentFields[key]));
        setPaymentFields({ ..._fields, [fieldName]: fieldValue });
        return;
      }

      setPaymentFields((prevState: { hasOwnProperty: (arg0: string) => void }) => {
        const shouldResetAdditionalFields = fieldName !== 'subMethod' && prevState?.hasOwnProperty('subMethod');

        if (shouldResetAdditionalFields) {
          setAdditionalFields(null);
          return { ...prevState, [fieldName]: fieldValue };
        }
        return { ...prevState, ...paymentFields, [fieldName]: fieldValue };
      });
    },
    [selectedPayment, paymentFields, additionalFields]
  );

  const selectPaymentGroup = (group: string): void => {
    setSelectedGroupOfPayment(group);
    setSelectedPaymentID(groupedPayments?.[group][0].id as number);
  };

  const handleSubmit = (): void => {
    if (
      process.env.REACT_APP_USE_COOKIES_GAMECARD === 'true' &&
      process.env.REACT_APP_USE_COOKIES === 'true' &&
      !cookiesAccepted
    ) {
      _openDialog({
        dialogType: EnumDialogsKeys.COOKIES_POPUP,
        dialogProps: { data: {} },
      });
    } else if (
      process.env.REACT_APP_KYC_ENABLE === 'true' &&
      (kycStatus === EnumStatusesOfKyc.PENDING ||
        kycStatus === EnumStatusesOfKyc.REJECTED ||
        kycStatus === EnumStatusesOfKyc.EXPIRED ||
        kycStatus === undefined)
    ) {
      _openDialog({
        dialogType: EnumDialogsKeys.KYC_POPUP,
        dialogProps: { data: {} },
      });
    } else {
      if (isPaymentDataValid(selectedPayment, paymentFields, t, wallets, setPaymentError, paymentError)) {
        const transactionData = getTransactionData(selectedPayment, user, method, locale);
        if (method === 'withdrawel' && process.env.REACT_APP_KYC_ENABLE === 'true') {
          const sdkInstance = (window as any)?.Legitimuz({
            token: '5cb851a5-fb77-4474-95ca-1780a5fc3bab',
            host: 'https://api.legitimuz.com',
            onSuccess: () => {
              setTimeout(() => {
                if (!isWithdrawelInProcess.current) {
                  isWithdrawelInProcess.current = true;
                  _setTransactions({ ...transactionData, ...paymentFields });
                }
              }, 0);
              setTimeout(() => {
                isWithdrawelInProcess.current = false;
              }, 10000);
            },
          });
          sdkInstance.mount();
          if (user?.personalInfo.kyc?.verificationStatus === 'verified') {
            sdkInstance.verifyDocument({ cpf: user?.personalInfo.nationalId });
          } else {
            sdkInstance.startFaceIndex({ cpf: user?.personalInfo.nationalId });
          }
        } else {
          _setTransactions({ ...transactionData, ...paymentFields });
        }
      }
    }
  };

  useEffect(() => {
    if (
      queries.navigateTo === 'deposit' &&
      method === 'deposit' &&
      queries.amount &&
      paymentFields &&
      !isDeepDepositValueSeted
    ) {
      if (process.env.REACT_APP_PIXEL_ENABLED as string) {
        setPurchaseData({
          isDone: true,
          purchaseAmount: queries.amount,
          purchaseCurrency: 'ARS', // MUST REPLACE AND MAKE DINAMIC
        });
      }

      setPaymentFields({ ...paymentFields, amount: queries.amount });
      setIsDeepDepositValueSeted(true);
    }
  }, [queries, paymentFields, method]);

  const closeNotification = (): void => {
    setShowTransactionResult(false);
  };

  const handlePaymentTabClick = (id: number): void => {
    _setTrasactionNotification(null);
    setSelectedPaymentID(id);
    closeNotification();
  };

  useEffect(() => {
    if (selectedPayment?.isIframe && !paymentFrameUrl) {
      setIsLoading(true);

      const params = {
        methodId: selectedPayment.methodId,
        method: selectedPayment.method,
        language: locale,
        type: selectedPayment.action,
      };

      getIframeURLRequest(params)
        .then((response) => {
          if (response?.success) {
            setPaymentFrameUrl(response.result.url);
          }
        })
        .catch((error) => {
          console.error('Failed to fetch iframe URL:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setPaymentFrameUrl('');
    }
  }, [selectedPayment]);

  const paymentFrame = useMemo(() => {
    return paymentFrameUrl ? (
      <iframe
        src={paymentFrameUrl}
        onLoad={() => setIsLoading(false)}
        className="payment-iframe"
        title="Payment Frame"
        width="100%"
        frameBorder="0"
        allow="clipboard-write"
      />
    ) : (
      <></>
    );
  }, [paymentFrameUrl]);

  const renderPaymentButton = useMemo(() => {
    if (selectedPayment?.isIframe) {
      return null;
    }

    return (
      <div className="payment_submit_button">
        <Button
          fontWeight="bold"
          variant="contained"
          color="primary"
          className={`success-filled-btn ${transactionLoading ? 'disabled' : ''}`}
          onClick={handleSubmit}
          loading={transactionLoading}
        >
          {t(paymentMethodsData && paymentMethodsData[0]?.action)}
        </Button>
      </div>
    );
  }, [selectedPayment?.isIframe, transactionLoading, handleSubmit, paymentMethodsData]);

  const goBackToGroups = (): void => {
    setSelectedGroupOfPayment('');
  };

  useEffect(() => {
    if (!!paymentMethodsData?.length && selectedPayment) {
      setPaymentFrameUrl('');
      setSelectedPayment(
        (paymentMethodsData as PaymentMethod[]).find((el: PaymentMethod) => el.id === selectedPaymentID) ?? null
      );
    }

    setPaymentError(null);
  }, [selectedPaymentID]);

  useEffect(() => {
    if (!!paymentMethodsData?.length) {
      setSelectedPaymentID(paymentMethodsData[0].id);
      setPaymentFrameUrl('');
      setSelectedPayment(
        (paymentMethodsData as PaymentMethod[]).find((el: PaymentMethod) => el.id === paymentMethodsData[0].id) ?? null
      );
    }
  }, [paymentMethodsData]);

  useEffect(() => {
    if (!!groupedPayments && !isMobileOnly) {
      const _defaultGroup = Object.keys(groupedPayments)[0];
      setSelectedGroupOfPayment(_defaultGroup);
      setSelectedPaymentID(groupedPayments[_defaultGroup][0].id);
    }
  }, [groupedPayments]);

  useEffect(() => {
    if (transactionStatus && selectedPayment) {
      configureRedirectWindow(transactionStatus, redirectWindow, selectedPayment);
    }
  }, [transactionStatus]);

  useEffect(() => {
    transactionNotification && setShowTransactionResult(true);
  }, [transactionNotification]);

  const _: any = useMemo(() => Object.values(additionalFields || {}), [additionalFields]);

  if (paymentMethodsData === null) {
    return (
      <div className="no_data_container">
        <Typography variant="h5">{t('payment_no_data')}</Typography>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="loader_container">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <div className="payment_container">
      <div className="payment_content">
        {!!selectedPayment && !!paymentMethodsData?.length && (
          <div className="payment_inner_content">
            {isMobileOnly && selectedGroupOfPayment && groupedPayments && (
              <GroupedPayment
                group={selectedGroupOfPayment}
                groupedPayments={groupedPayments}
                selectedGroupOfPayment={selectedGroupOfPayment}
              />
            )}
            <div className="tabs_section">
              {isMobileOnly && selectedGroupOfPayment && (
                <SvgIcon src={icons.arrowDown} className="back" onClick={goBackToGroups} />
              )}
              <span className="tabs_section__title">{t('choosePaymentMethod')}</span>
            </div>
            <div className="payment">
              {groupedPayments && !selectedPayment?.isIframe && (
                <div
                  className={cn('payment--grouped-payments', { ['hide']: isMobileOnly && !!selectedGroupOfPayment })}
                >
                  <GroupedPayments
                    groupedPayments={groupedPayments}
                    selectedGroupOfPayment={selectedGroupOfPayment}
                    selectPaymentGroup={selectPaymentGroup}
                  />
                </div>
              )}
              <div className={cn('input_container')}>
                <div
                  className={cn('tabs_section__items', generalConfigs?.paymentTemplate ?? '', {
                    ['grouped']: groupedPayments,
                  })}
                >
                  <PaymentTabs
                    paymentMethodsData={paymentMethodsData}
                    groupedPayments={groupedPayments}
                    selectedPayment={selectedPayment}
                    selectedPaymentID={selectedPaymentID}
                    handlePaymentTabClick={handlePaymentTabClick}
                  />
                </div>
                <div className={cn({ ['grouped_content']: groupedPayments }, 'payment_bottom_content')}>
                  {groupedPayments && (
                    <Typography variant="h6" className={'payment_information_item__name'}>
                      {selectedPayment.name}
                    </Typography>
                  )}
                  <PaymentInformation t={t} selectedPayment={selectedPayment} />
                  {!!selectedPayment?.longDescription?.length && !selectedPayment?.isIframe && (
                    <div className="payment__pament_rules">{reachTextParser(selectedPayment?.longDescription)} </div>
                  )}
                  <div className={cn('payment_content', { ['grouped']: groupedPayments })}>
                    {selectedPayment?.fields &&
                      !selectedPayment?.isIframe &&
                      Constructor.renderInputs(
                        selectedPayment,
                        handleFieldChange,
                        paymentError,
                        setPaymentError,
                        method,
                        paymentFields
                      )}
                    {!!additionalFields &&
                      !selectedPayment?.isIframe &&
                      Constructor.renderAdditionalInputs(
                        _,
                        handleFieldChange,
                        // bad solution for pixKey method which returns from BE
                        paymentError?.pixType ? { ...paymentError, pixKey: paymentError.pixType } : paymentError,
                        setPaymentError,
                        selectedPayment.id,
                        paymentFields
                      )}
                  </div>
                  {paymentFrame}
                </div>
                {paymentFields?.pixType === 'mobile' && <p className="phone_example">dddnúmero ex: 11999999999</p>}
                {renderPaymentButton}
              </div>
            </div>
          </div>
        )}
        <PaymentNotification
          closeNotification={closeNotification}
          showTransactionResult={showTransactionResult}
          transactionNotification={transactionNotification}
        />
      </div>
      {process.env.REACT_APP_PIXEL_ENABLED === 'true' && purchaseData.isDone && (
        <WordPressConversionPixel
          wpbType="purchase"
          wpbAmount={purchaseData.purchaseAmount} // Replace with actual amount
          wpbCurrency={purchaseData.purchaseCurrency} // Replace with actual currency
          // wpbProductId={purchaseProductId} // Replace with actual product ID
        />
      )}
    </div>
  );
};
export default PaymentView;
