import cn from 'classnames';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IActiveRafflesType } from 'src/types/res-dto/raffleDraw';
import './styles.scss';

type IProps = {
  classnames?: string;
  data: IActiveRafflesType;
};

const RaffleDrawRules: FC<IProps> = ({ classnames, data }) => {
  const { t } = useTranslation();

  return (
    <div className={cn('raffle_draw_rules_container', classnames)}>
      <h3 className="raffle_draw_rules_title">{t('rules')}</h3>
      <div className="raffle_draw_rules_content">
        <img
          src={`/images/raffleDraw/raffle_draw_tickets.png`}
          alt="raffle_draw_tickets"
          className="raffle_draw_tickets_img"
        />
        <div className="raffle_draw_rule_text">
          <p>{data.description}</p>
        </div>
      </div>
    </div>
  );
};

export default RaffleDrawRules;
