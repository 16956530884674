import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {} from 'src/components/base-components/ProviderList/ProviderList';
import { Checkbox } from 'src/components/common/Checkbox';
import { SvgIcon } from 'src/components/common/SvgIcon';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { MediaManager } from 'src/manager/MediaManager';
import { setSelectedProvider } from 'src/store/games/actions';
import { toggleMobileProvider } from 'src/store/mobile/actions';
import { Button } from '../../../../common/Button';
import _styles from '../styles.module.scss';

type Props = {
  providers: string[];
  selectedProvidersList: string[];
  selectProvider: (provider: string) => () => void;
};
const MultiSelectProvidersList: FC<Props> = ({ selectedProvidersList, providers, selectProvider }) => {
  const { t }: Translation = useTranslation();
  const _toggleMobileProvider = useConnectedAction(toggleMobileProvider);
  const _setSelectedProvider = useConnectedAction(setSelectedProvider);

  const filterProvider = (): void => {
    _setSelectedProvider(selectedProvidersList);
    _toggleMobileProvider();
    setBodyOverflow('set');
  };

  return (
    <div className={_styles.provider_multiSelect_list}>
      <div className={cn(_styles.checkbox_dropdown_list_item)}>
        <Checkbox
          containerClassName={_styles.checkbox_dropdown_list_item_wrapper}
          onChange={selectProvider('all_providers')}
          value={selectedProvidersList.includes('all_providers')}
          name={'all_providers'}
          id={'all_providers'}
          label={<p className={_styles.checkbox_dropdown_list_item_text}>{t('all_providers')}</p>}
          defaultChecked={selectedProvidersList.includes('all_providers')}
        />
      </div>

      {providers?.map((provider: string) => {
        return (
          <div key={provider} className={_styles.checkbox_dropdown_list_item}>
            <Checkbox
              containerClassName={_styles.checkbox_dropdown_list_item_wrapper}
              onChange={selectProvider(provider)}
              value={selectedProvidersList.includes(provider)}
              name={provider}
              id={provider}
              label={
                <SvgIcon
                  nameClassName={_styles.checkbox_dropdown_list_item_text}
                  src={MediaManager.getSrcFromGeneralMediaAssets(
                    `${provider.toLowerCase()}.svg`,
                    `/providerIcons/BlackMode/`
                  )}
                  name={provider}
                />
              }
              defaultChecked={selectedProvidersList.includes(provider)}
            />
          </div>
        );
      })}
      <Button
        className={`provider_filter ${selectedProvidersList.length === 0 ? 'secondary-btn' : 'success-filled-btn'}`}
        disabled={selectedProvidersList.length === 0}
        onClick={filterProvider}
      >
        {t('filter')}
      </Button>
    </div>
  );
};
export default MultiSelectProvidersList;
