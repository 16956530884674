import { FirstDepositToWallet } from 'src/types/res-dto/first_deposit_to_wallet';
import { IUserBalance, LoginHistory } from 'src/types/store-types';
import { APP_API_VERSION } from 'src/utils/constants';
import HttpClient from './http';

const balanceRequest = (): Promise<IUserBalance> => {
  return HttpClient.get({
    path: `${APP_API_VERSION}/getBalance`,
    baseURL: process.env.REACT_APP_REMOTE as string,
  });
};

const firstDepositToBonusRequest = (params: any): Promise<FirstDepositToWallet> => {
  return HttpClient.post(
    {
      path: `${APP_API_VERSION}/firstDepositToBonusWallet`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    params
  );
};

const loginHistoryRequest = (params: Record<string, any>): Promise<LoginHistory> => {
  return HttpClient.get(
    { path: `${APP_API_VERSION}/getLoginHistory`, baseURL: process.env.REACT_APP_REMOTE as string },
    true,
    params
  );
};

const closeAccountRequest = (params: Record<string, any>): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/closeAccount`, baseURL: process.env.REACT_APP_REMOTE as string },
    params
  );
};

const inactivityForceLogOutRequest = (params: Record<string, any>): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/logout`, baseURL: process.env.REACT_APP_REMOTE as string },
    params
  );
};

const verifyEmail = (payload: { key: string }): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/confirmEmail`, baseURL: process.env.REACT_APP_REMOTE as string },
    payload,
    false
  );
};

const transactionRequest = (params: any, subSection: string): Promise<any> => {
  if (subSection === 'casino_history') {
    params.groupByGame = true;
  }

  return HttpClient.get(
    { path: `${APP_API_VERSION}/bc/getMovements`, baseURL: process.env.REACT_APP_REMOTE as string },
    true,
    params
  );
};

const getPendingTransaxtions = (): Promise<any> => {
  return HttpClient.get(
    { path: `${APP_API_VERSION}/getRequesterTransactionRequests`, baseURL: process.env.REACT_APP_REMOTE as string },
    true,
    { status: 'pending', subType: 'withdraw' }
  );
};

const cancelPendingTransaction = (transactionId: string): Promise<any> => {
  return HttpClient.patch(
    { path: `${APP_API_VERSION}/cancelTransactionRequest`, baseURL: process.env.REACT_APP_REMOTE as string },
    { transactionId },
    undefined,
    undefined,
    true
  );
};

const changePasswordRequest = (params: Record<string, any>): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/bc/changePassword`, baseURL: process.env.REACT_APP_REMOTE as string },
    params
  );
};

const checkOldPasswordRequest = (params: Record<string, any>): Promise<any> => {
  return HttpClient.post(
    { path: `${APP_API_VERSION}/authenticatePlayer`, baseURL: process.env.REACT_APP_REMOTE as string },
    params
  );
};

const paymentMethodsRequest = (params: any): Promise<PaymentMethod[]> => {
  return HttpClient.get(
    {
      path: `${APP_API_VERSION}/getAccountPaymentMethodsV2`,
      baseURL: process.env.REACT_APP_REMOTE as string,
    },
    true,
    params
  );
};

const paymentMethodsIframeRequest = (params: any): Promise<PaymentMethod[]> => {
  return HttpClient.get(
    {
      path: `/payments/getAccountPaymentMethods`,
      baseURL: process.env.REACT_APP_PAYMENT_REMOTE as string,
    },
    true,
    params
  );
};

const getIframeURLRequest = (params: any): Promise<any> => {
  return HttpClient.get(
    {
      path: `/payments/getIframeURL`,
      baseURL: process.env.REACT_APP_PAYMENT_REMOTE as string,
    },
    true,
    params
  );
};

const bankRequest = (url: string, methodId: string): Promise<any> => {
  const isNewPaymentMethod = process.env.REACT_APP_NEW_PAYMENT_METHOD === 'true';

  return HttpClient.get(
    {
      path: url,
      baseURL: isNewPaymentMethod
        ? (process.env.REACT_APP_PAYMENT_REMOTE as string)
        : (process.env.REACT_APP_REMOTE as string),
    },
    true,
    { methodId: methodId }
  );
};

const paymentTransactionRequest = (data: any): Promise<any> => {
  return HttpClient.post(
    { path: `/${data.method}/${data.transactionType.toLowerCase()}`, baseURL: process.env.REACT_APP_REMOTE as string },
    data
  );
};

const newPaymentTransactionRequest = (data: { transactionType: string }): Promise<any> => {
  return HttpClient.post(
    {
      path: `/payments/${data.transactionType.toLowerCase()}`,
      baseURL: process.env.REACT_APP_PAYMENT_REMOTE as string,
    },
    data
  );
};

const initSdkLegitimuzRequest = (data: {
  meta: { cpf: string };
  deviceinfo: string;
  hardware_concurrency: number;
}): Promise<any> => {
  const token = '5cb851a5-fb77-4474-95ca-1780a5fc3bab';
  const action = 'signin';
  const betsafeAction = 'signin';

  return HttpClient.post(
    {
      path: `/external/fraud-prevention/analysis?token=${token}&action=${action}&betsafe_action=${betsafeAction}`,
      baseURL: 'https://api.legitimuz.com',
    },
    data
  );
};

const getBankListRequest = (payment: any): Promise<any> => {
  return HttpClient.get(
    { path: `/${payment.method}/getBankList`, baseURL: process.env.REACT_APP_REMOTE as string },
    true,
    {
      methodId: payment.methodId,
    }
  );
};

export {
  balanceRequest,
  bankRequest,
  cancelPendingTransaction,
  changePasswordRequest,
  checkOldPasswordRequest,
  closeAccountRequest,
  firstDepositToBonusRequest,
  getBankListRequest,
  getIframeURLRequest,
  getPendingTransaxtions,
  inactivityForceLogOutRequest,
  initSdkLegitimuzRequest,
  loginHistoryRequest,
  newPaymentTransactionRequest,
  paymentMethodsIframeRequest,
  paymentMethodsRequest,
  paymentTransactionRequest,
  transactionRequest,
  verifyEmail,
};
