import moment from 'moment';
import React, { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'src/components/common/Input';
import PopUpContainer from 'src/components/common/modal/PopUp';
import { getSkinId, setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { signInVerification } from 'src/services/authorizationApi';
import { closeDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys } from 'src/store/dialog/types';
import { setBalance, signIn } from 'src/store/user/actions';

interface IProps {
  data?: any;
}

const Player2StepPopup: FC<IProps> = ({ data }) => {
  const { t }: Translation = useTranslation();
  const skinId = getSkinId();
  const [value, setValue] = useState<string>('');

  setBodyOverflow('unset');

  const _closeDialog = useConnectedAction(closeDialog);
  const _setBalance = useConnectedAction(setBalance.success);
  const _signInSuccess = useConnectedAction(signIn.success);
  const _signInLoad = useConnectedAction(signIn.load);

  const removeDialog = (): void => {
    setBodyOverflow('set');
    _signInLoad(false);
    _closeDialog({ dialogType: EnumDialogsKeys.PLAYER_2_STEP });
  };

  const handleSubmit = async (): Promise<void> => {
    const res = await signInVerification({ username: data.username, key: value, skinId: skinId });

    if (res.success) {
      const now = moment();
      const storedDate = now.add(res.result.exp, 'seconds').valueOf();

      _setBalance(res.result?.wallets);

      if (res.result?.token && res.result?.id && res.result?.username) {
        window.__gdlib?.login(res.result?.token);
      }
      const user = {
        ...res.result,
        personalInfo: { ...res.result.personalInfo, emailVerified: Boolean(res.result.personalInfo.emailVerified) },
      };
      localStorage.setItem('wallets', JSON.stringify(res.result?.wallets));
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('sessionId', Date.now().toString());
      localStorage.setItem('storedDate', String(storedDate));

      _signInSuccess({ ...user, storedDate });
      _signInLoad(false);

      data.onSuccessCb();
      removeDialog();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
  };

  return (
    <PopUpContainer
      isOpen
      handleClose={removeDialog}
      handleOK={handleSubmit}
      disabled={!value}
      hasOverlay={false}
      title={t('playerTwoStepAuth')}
      zIndex={151}
    >
      <Input name="key" value={value} onChange={handleChange} />
    </PopUpContainer>
  );
};

export default Player2StepPopup;
