import { ChangeEvent, FC, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useResetSignUpError } from 'src/hooks/useResetSignupErrors';
import { setError } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import * as Yup from 'yup';
import { openMyAccountModal } from '../../../store/account-modal/actions';
import { Checkbox } from '../Checkbox';

type IProps = {
  section: string;
  subSection: string;
  onChange: (e: any) => void;
  fullHeight: boolean;
  id: string;
};
const AntiMoneyLaunderingPolicy: FC<IProps> = ({ onChange, id, section, subSection, fullHeight }) => {
  // Hooks
  const { t }: Translation = useTranslation();
  const [antiMoneyLaunderingCheckbox, setAntiMoneyLaunderingCheckbox] = useState<boolean>(false);

  // Redux
  const _setErrorReq = useConnectedAction(setError);
  const _openAccountModal = useConnectedAction(openMyAccountModal);
  const resetSignUpError = useResetSignUpError();

  // Selectors
  const { errors: signUpErrors } = useSelector((state: RootState) => state.user);

  // YUP Validation
  const validationSchema = Yup.object().shape({
    antiMoneyLaunderingPolicy: Yup.boolean().oneOf([true], t('requiredField')).required(t('requiredField')),
  });

  const openPrivacyPolicyModal = (e: MouseEvent<HTMLSpanElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    _openAccountModal({ section, subSection, fullHeight });
  };

  const antiMoneyLaunderingPolicy = useMemo(() => {
    return (
      <>
        {t('politicallyExposedPersonDeclaration')}
        <span className="terms_and_conditions_checkbox__link" onClick={(e) => openPrivacyPolicyModal(e)}>
          {t('antiMoneyLaunderingPolicyReference')}
        </span>
      </>
    );
  }, []);

  const handleValidate = async (value: boolean): Promise<void> => {
    try {
      await validationSchema.validate({ antiMoneyLaunderingPolicy: value }, { abortEarly: false });
      resetSignUpError('antiMoneyLaunderingPolicy');
    } catch (error: any) {
      if (error.name === 'ValidationError') {
        _setErrorReq({ ...signUpErrors, antiMoneyLaunderingPolicy: error.errors[0] });
      }
    }
  };

  // Actions
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setAntiMoneyLaunderingCheckbox(e.target.checked);
    handleValidate(e.target.checked).catch((e) => console.log(e));
    if (signUpErrors.antiMoneyLaunderingPolicy) {
      resetSignUpError('antiMoneyLaunderingPolicy');
    }
    onChange(e);
  };

  return (
    <Checkbox
      error={signUpErrors.antiMoneyLaunderingPolicy}
      onChange={handleOnChange}
      label={antiMoneyLaunderingPolicy}
      id={id}
      name="antiMoneyLaunderingPolicy"
      value={antiMoneyLaunderingCheckbox}
    />
  );
};
export default AntiMoneyLaunderingPolicy;
