const MediaManager = {
  getSrcFromMediaAssets: (src: string, section: string): string => {
    return process.env.REACT_APP_MEDIA_ASSETS + section + src;
  },
  getSrcFromGeneralMediaAssets: (src: string, section: string): string => {
    return process.env.REACT_APP_GENERAL_MEDIA_ASSETS + section + src;
  },
  getSrcFromLocaleFolder: (src: string, section: string): string => {
    return '/images' + section + src;
  },
};
export { MediaManager };
