import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setBodyOverflow } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { setSelectedCategory } from 'src/store/app/actions';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { openDialog } from 'src/store/dialog/actions';
import { EnumDialogsKeys, EnumStatusesOfKyc } from 'src/store/dialog/types';
import { setGameBoxMissions, setTournamentGame } from 'src/store/integrations/actions';
import { setBalance } from 'src/store/user/actions';
import { RootState } from 'src/types/store-types';
import './styles.scss';

const GosView = (): JSX.Element => {
  const navigate = useNavigate();
  const [fullScreen, setFullScreen] = useState(false);
  const GosSDK = useRef<GosSDK>();
  const { locale } = useI18n();
  const { user, kycStatus } = useSelector((state: RootState) => state.user);
  const sessionRecorder = useSessionRecorder();
  const _openDialog = useConnectedAction(openDialog);
  const _setSelectedCategory = useConnectedAction(setSelectedCategory);
  const _openAuthModal = useConnectedAction(openAuthModal);
  const _requestGameBoxMission = useConnectedAction(setGameBoxMissions.req);
  const _resetExternalTournamentGame = useConnectedAction(setTournamentGame);
  const _requestBalance = useConnectedAction(setBalance.req);
  const { selectedTournamentGame, showTournamentPage } = useSelector((state: RootState) => state.integrations);
  const { section } = useSelector((state: RootState) => state.accountModal);

  const registerListeners = (): void => {
    GosSDK.current?.addEventListener('spinAndGoStarted', (event) => {
      _openDialog({
        dialogType: EnumDialogsKeys.SPIN_AND_GO_STARTED,
        dialogProps: { data: { tournamentId: event.tournamentId } },
      });
    });

    GosSDK.current?.addEventListener('navigate/category', () => {
      _setSelectedCategory('silvaSeries');
      navigate(`/category/silvaSeries`);
    });

    GosSDK.current?.addEventListener('changeCategory', (event) => {
      _setSelectedCategory(event.category);
      navigate(`/category/${event.category}`);
    });

    GosSDK.current?.addEventListener('resumeTournament', (event) => {
      _openDialog({
        dialogType: EnumDialogsKeys.RESUME_TOURNAMENT,
        dialogProps: { data: event.tournament },
      });
    });

    GosSDK.current?.addEventListener('successfullRegistration', (event) => {
      localStorage.setItem('resumeGameId', event.data.id);
    });

    GosSDK.current?.addEventListener('successfullUnregistration', () => {
      localStorage.removeItem('resumeGameId');
    });

    GosSDK.current?.addEventListener('openLoginForm', () => {
      setBodyOverflow('unset');
      _openAuthModal('login');
    });

    GosSDK.current?.addEventListener('balanceUpdate', () => {
      _requestBalance();
    });

    GosSDK.current?.addEventListener('gameStateChanged', (event) => {
      if (!event.fullScreen) {
        setTimeout(() => {
          if (user) {
            _requestGameBoxMission();
            _resetExternalTournamentGame(null);
          }
        }, 500);
      }

      setFullScreen(event.fullScreen);
    });

    GosSDK.current?.addEventListener('scrollLock', (event) => {
      setBodyOverflow(event.value ? 'set' : 'unset');
    });
  };

  const handleLangChange = useCallback(() => {
    GosSDK.current?.actions.handleLanguageChange(locale);
  }, [locale]);

  const handleGameOpen = useCallback(() => {
    GosSDK.current?.actions.handleOpenGame(selectedTournamentGame);
  }, [selectedTournamentGame]);

  const handleShowTournamentPage = useCallback(() => {
    GosSDK.current?.actions.handleOpenDialogByHostInitiative('howToPlay');
  }, [showTournamentPage]);

  const authorizeUser = useCallback((): void => {
    user
      ? GosSDK.current?.actions?.handleGDLoginAction({ ...user, userId: user.id })
      : GosSDK.current?.actions?.handleGDLogoutAction();
  }, [user]);

  const mountGosApp = (): void => {
    setBodyOverflow('set');

    if (!window.__gdlib?.__GosSDK) return;

    if (window.__gdlib.__GosSDK?.init) {
      window.__gdlib.__GosSDK.init().then(() => {
        GosSDK.current = window.__gdlib?.__GosSDK.instance;
        GosSDK.current?.mount({
          onReady: () => {
            registerListeners();
            authorizeUser();
          },
        });
      });
      return;
    }

    const __GosSDKProxy = new Proxy(window.__gdlib?.__GosSDK, {
      set(target: any, prop, newValue) {
        target[prop] = newValue;
        if (prop === 'init' && typeof newValue === 'function') {
          newValue().then(() => {
            GosSDK.current = target.instance;
            GosSDK.current?.mount({
              onReady: () => {
                registerListeners();
                authorizeUser();
              },
            });
          });
        }
        return true;
      },
    });

    window.__gdlib.__GosSDK = __GosSDKProxy;
  };

  useEffect(() => {
    if (GosSDK.current) {
      authorizeUser();
    }
  }, [authorizeUser]);

  useEffect(() => {
    if (!GosSDK.current?.actions) return;

    handleShowTournamentPage();
  }, [handleShowTournamentPage]);

  useEffect(() => {
    if (!GosSDK.current?.actions) return;

    handleGameOpen();
  }, [handleGameOpen]);

  useEffect(() => {
    if (!GosSDK.current?.actions) return;

    handleLangChange();
  }, [handleLangChange]);

  useEffect(() => {
    mountGosApp();

    return () => {
      GosSDK.current?.unMount();
    };
  }, []);

  useEffect(() => {
    sessionRecorder?.trackEvent('Gos page view');
  }, []);

  useEffect(() => {
    let interval: any;

    if (
      !section &&
      user &&
      process.env.REACT_APP_KYC_ENABLE === 'true' &&
      (kycStatus === EnumStatusesOfKyc.PENDING ||
        kycStatus === EnumStatusesOfKyc.EXPIRED ||
        kycStatus === EnumStatusesOfKyc.REJECTED ||
        kycStatus === undefined)
    ) {
      interval = setInterval(() => {
        _openDialog({
          dialogType: EnumDialogsKeys.KYC_POPUP,
          dialogProps: { data: {} },
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [kycStatus, user, section]);

  return (
    <div className={cn('page_container', 'gosView_Wrapper', { ['gosView_Wrapper--mobile']: isMobile })}>
      <div className={cn('gosView_Container', { ['fullScreen']: fullScreen })} id="__GOS__" />
    </div>
  );
};

export default GosView;
