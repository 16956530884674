import cn from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { isDesktop, isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';
import Template_2_3 from 'src/components/base-components/HomeWidgets/components/Template_2_3';
import CarouselSlideItem from 'src/components/base-components/Slider/BannerSlider/SlidetItem';
import { Arrows } from 'src/components/base-components/Slider/components/Arrows';
import HomePageGamesSlider from 'src/components/base-components/Slider/HomePageGamesSlider';
import { SliderDataTransforamtionHelper } from 'src/helpers/SliderDataTransformationHelper';
import { transformBanners } from 'src/helpers/transformers';
import { detectDevice, getRowNumber, scrollToTop } from 'src/helpers/utils';
import { useConnectedAction } from 'src/hooks/use-connected-action';
import { useI18n } from 'src/hooks/use-i18n-translation';
import { useSessionRecorder } from 'src/hooks/use-session-recorder';
import { useBannerActions } from 'src/hooks/useBannerActions';
import { CMSManager } from 'src/manager/CMSManager';
import { getBanners, getHomeGrid } from 'src/services/dataApi';
import PersisterService from 'src/services/PersisterService';
import { openAuthModal } from 'src/store/auth-modal/actions';
import { HomeBanner, SliderBanner } from 'src/types/common-types/slider';
import { RootState } from 'src/types/store-types';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Template1 from '../../components/base-components/Slider/components/Template1';
import Template2 from '../../components/base-components/Slider/components/Template2';
import { getLastPlayedGamesData } from '../../services/gameApi';
import { bannerData, initialTagsData, widgetData } from './initialData';
import './styles.scss';

const CATEGORY = 'home';

const pagination = {
  clickable: true,
  renderBullet: function (index: number, className: string) {
    return '<span class="' + className + '" key="' + index + '"></span>';
  },
};
type ITagsDataProps = {
  category: string;
  limit: number;
  tagName: string;
  trTagName: string;
  template: string;
};

const StaticHomePage: FC = () => {
  const [banners, setBanners] = useState<SliderBanner[][]>([bannerData][0]);
  const [widgets, setWidgets] = useState<HomeBanner[]>(widgetData);
  const [tagsData, setTagsData] = useState<ITagsDataProps[]>(initialTagsData);
  const { locale } = useI18n();
  const device = detectDevice();
  const [my_swiper, set_my_swiper] = useState<any>({});
  const _openAuthModal = useConnectedAction(openAuthModal);
  const sessionRecorder = useSessionRecorder();
  const { loginAction, registrAction, promotionAction, gameAction, categoryAction, externalUrlAction } =
    useBannerActions();
  const { user } = useSelector((state: RootState) => state.user);
  const { pageTagsPreviews } = useSelector((state: RootState) => state.configs);
  const lng = localStorage.getItem('selectedLanguage');
  const [lastPlayedGames, setLastPlayedGames] = useState<[]>([]);
  const showPlayedGAmes: boolean = process.env.REACT_APP_SHOW_PLAYED_GAMES === 'true';
  const hasFetched = useRef(false);

  const getBannersreq = async (): Promise<void> => {
    const _deviceType = device === 'tablet' ? 'desktop' : device;

    const res: any = await getBanners(_deviceType, locale);
    const _ = transformBanners(
      CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string]?.getStaticBannersData(res.data)
    );
    let _banners = { [locale]: _ };
    if (PersisterService.hasPersistValue('banners')) {
      _banners = { ...PersisterService.getPersistValue('banners'), [locale]: _ };
    }
    PersisterService.setPersistValue('banners', _banners);
    setBanners(_);
  };

  const getBannersData = (): void => {
    if (PersisterService.hasPersistValue('banners') && PersisterService.getPersistValue('banners')?.[locale]) {
      try {
        setBanners(PersisterService.getPersistValue('banners')?.[locale]);
      } catch {
        localStorage.setItem('crashError', sessionStorage.getItem('banners') as string);
        getBannersreq();
      }
    } else {
      getBannersreq();
    }
  };

  useEffect(() => {
    getBannersData();
  }, [device, locale]);

  const bannersArray = useMemo(() => {
    if (!!banners[CATEGORY] && Array.isArray(banners['home'])) {
      const _prioritizesBanners = banners['home'].sort(
        (a: { priority: number }, b: { priority: number }) => b?.priority - a?.priority
      );
      return SliderDataTransforamtionHelper.setSliderItemObj(_prioritizesBanners, 1);
    }

    return [];
  }, [banners]);

  const redirect = (ev: any, attributes: any): any => {
    if (process.env.REACT_APP_GLOBAL_NEED_LOGIN && !user) {
      return _openAuthModal('login');
    }

    const redirection = attributes?.OnClickRedirection;
    const redirectParams = {
      target: redirection ? redirection?.Target : attributes?.target,
      param: redirection ? redirection?.TargetParam : attributes?.targetParam,
    };

    attributes ? ev.stopPropagation() : ev.preventDefault();

    switch (redirectParams.target) {
      case 'goToRegister':
        registrAction();
        break;
      case 'goToLogin':
        loginAction();
        break;
      case 'goToPromotion':
        promotionAction(redirectParams.param);
        break;
      case 'goToExternalUrl':
        externalUrlAction();
        break;
      case 'goToGame':
        gameAction(redirectParams.param);
        break;
      case 'goToCategory':
        categoryAction(redirectParams.param);
        scrollToTop();
        break;
      case 'goToRace':
      case 'noAction':
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    let result = null;

    if (PersisterService.hasPersistValue('homeGrid'?.[locale])) {
      result = PersisterService.getPersistValue('homeGrid')[locale];
      const widgets = result.sort((a: any, b: any) => a.priority - b.priority);
      setWidgets(widgets);
    } else {
      const _deviceTtype = device === 'tablet' ? 'desktop' : device;

      getHomeGrid(_deviceTtype, locale).then((res: any) => {
        if (!!res?.data.length) {
          const _ = {
            [locale]: CMSManager[process.env.REACT_APP_FILE_STORAGE_TYPE as string]?.getPageGridData(res.data),
          };

          PersisterService.setPersistValue('homeGrid', _);
          const widgets = _[locale]?.sort(
            (a: { priority: number }, b: { priority: number }) => a.priority - b.priority
          );
          setWidgets(widgets);
        }
      });
    }
  }, [locale]);

  useEffect(() => {
    if (pageTagsPreviews && lng) {
      const newTagsData = pageTagsPreviews.map((item: Record<string, string>) => ({
        category: item.category,
        limit: 24,
        tagName: item.tag,
        trTagName: item.translations[lng],
        template: 'template_' + getRowNumber(item.viewType),
        priority: item.priority,
      }));
      setTagsData(newTagsData);
    }
  }, [lng]);

  const getLastPlayedGames = (): void => {
    getLastPlayedGamesData().then((res) => {
      if (res) {
        setLastPlayedGames(res.result['games']);
      }
    });
  };

  useEffect(() => {
    if (!user) {
      hasFetched.current = false;
    }
  }, [user]);

  useEffect(() => {
    if (user && showPlayedGAmes && !hasFetched.current) {
      getLastPlayedGames();
      hasFetched.current = true;
    }
  }, [user, showPlayedGAmes]);

  useEffect(() => {
    sessionRecorder?.trackEvent('Home page view');
  }, []);

  return (
    <div className="home_container">
      <div className={cn('banner_container', 'rounded-16')}>
        <Swiper
          className="carousel_container"
          slidesPerView={1}
          spaceBetween={24}
          autoplay={{
            delay: 5000,
          }}
          speed={1500}
          slidesPerGroup={1}
          loop
          pagination={pagination}
          navigation={{ nextEl: '.arrow-right', prevEl: '.arrow-left' }}
          modules={[Pagination, Navigation, Autoplay]}
          onInit={(ev: any) => {
            set_my_swiper(ev);
          }}
        >
          {bannersArray?.map((carouselItemArr, i: number) => {
            return (
              <SwiperSlide key={i}>
                <div className="carousel_item_container">
                  {carouselItemArr.map((carouselItemData, j) => {
                    return <CarouselSlideItem cols={12} rows={2} key={j} carouselItemData={carouselItemData} />;
                  })}
                </div>
              </SwiperSlide>
            );
          })}
          {isDesktop && bannersArray.length > 1 && (
            <Arrows
              rows={2}
              placement={'rightBottom'}
              type={'default'}
              template={'template_noFullScreen'}
              size={'lg'}
              nextClick={() => my_swiper?.slideNext()}
              prevClick={() => my_swiper?.slidePrev()}
            />
          )}
        </Swiper>
      </div>
      <div className="home_widget_template_2-3">
        <Template_2_3 widgets={widgets} redirect={redirect} />
      </div>
      <div className="home_slider_container">
        {lastPlayedGames.length && user && showPlayedGAmes ? (
          <>
            {isDesktop && lastPlayedGames.length >= 12 ? (
              <Template1 activeGames={lastPlayedGames} tagName="keep_playing" />
            ) : (
              (isMobileOnly || lastPlayedGames.length < 12) && (
                <Template2 activeGames={lastPlayedGames} tagName="keep_playing" />
              )
            )}
          </>
        ) : (
          <> </>
        )}
        {[...initialTagsData, ...tagsData]
          ?.sort((a: { priority: number }, b: { priority: number }) => b?.priority - a?.priority)
          ?.map((m) => {
            return (
              <HomePageGamesSlider
                key={m.tagName}
                category={m.category || 'none'}
                tagName={m.tagName}
                trTagName={m?.trTagName}
                template={m.template}
                limit={m.limit}
                showViewAllLink={m.category !== null}
              />
            );
          })}
      </div>
    </div>
  );
};

export default StaticHomePage;
